import { CurrencyAmount, Token } from '@uniswap/sdk-core'

import { CRN } from '../constants/tokens'
import { unwrappedToken } from './unwrappedToken'

export const getPairRenderOrder = (token0: Token, token1: Token) => {
  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const token0IsFirst = {
    currency0,
    currency1,
    token0,
    token1,
  }
  const token1IsFirst = {
    currency0: currency1,
    currency1: currency0,
    token0: token1,
    token1: token0,
  }

  // If pair has CETH, put CETH second
  //      If CRN is the other token, it'll be first
  /*
  if (currency0 === CETH || currency1 === CETH) {
    return currency0 === CETH ? token1IsFirst : token0IsFirst
  }
  */

  // If pair has CRN, put CRN first
  return token0.equals(CRN[token0.chainId]) ? token0IsFirst : token1IsFirst
}

export const isTokenAmountPositive = (stakedAmount: CurrencyAmount<Token> | null | undefined) => {
  return Boolean(stakedAmount?.greaterThan('0') ?? false)
}
