import { Token } from '@uniswap/sdk-core'

import { STABLE_SWAP_ADRESS } from './addresses'
import { SupportedChainId } from './chains'
import { DAI, USDC, USDT, UST } from './tokens'

export type Pool = {
  poolName?: string
  subtitle?: string
  description?: string
  poolImage?: string
  lpToken: Token
  poolTokens: Token[]
  address: string
  rewardPoolId: number | null
}

export type PoolsMap = {
  [poolId: string]: Pool
}

// DANGER: LIST POOL TOKENS BY THEIR CORRECT ORDER IN THE CONTRACT, CALLS MAY BEHAVE INCORRECTLY OTHERWISE

export const POOLS_MAP: PoolsMap = {
  '0': {
    poolName: 'Hermes',
    subtitle: 'Nomad Stablepool',
    description: 'Swap between assets bridged from Nomad and other stablecoins',
    lpToken: new Token(9000, '0xD9DAc7f9Bbd7d9dc604d0749a1EB4f5b80f0a9b3', 18, 'SCLP', 'Stable Cronus Liquidity Pool'),
    poolTokens: [
      USDT[SupportedChainId.EVMOS_TESTNET],
      USDC[SupportedChainId.EVMOS_TESTNET],
      DAI[SupportedChainId.EVMOS_TESTNET],
      UST[SupportedChainId.EVMOS_TESTNET],
    ],
    address: STABLE_SWAP_ADRESS[SupportedChainId.EVMOS_TESTNET],
    rewardPoolId: null,
  },
}
