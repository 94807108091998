import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useInterfaceMulticall } from 'hooks/useContract'
import { ReactNode, useState } from 'react'
import { SuperFarmResult } from 'state/stake/user-farms'
import styled from 'styled-components/macro'

import { useSuperFarmingContract } from '../../state/stake/hooks-sushi'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, ThemedText } from '../../theme'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import { RowBetween } from '../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  lockIndex: number
  stakingInfo: SuperFarmResult
}

export default function SupersClaimRewardModal({ isOpen, onDismiss, lockIndex, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  const doubleRewardsOn = stakingInfo.doubleRewards
  const doubleRewardToken = stakingInfo.doubleRewardToken
  const noCrnRewards = stakingInfo.noCrnRewards

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }
  const multicall = useInterfaceMulticall()
  const contract = useSuperFarmingContract(stakingInfo.stakingRewardAddress)

  async function onClaimReward() {
    if (contract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      // await multicall.multicall({target: contract.address, gasLimit: , })
      await contract
        .claim(lockIndex)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.CLAIM,
            recipient: account!,
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <div>
          <ContentWrapper gap="lg">
            <RowBetween>
              <ThemedText.MediumHeader>
                <Trans>Claim</Trans>
              </ThemedText.MediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            {stakingInfo?.earnedAmount && (
              <AutoColumn justify="center" gap="md">
                <ThemedText.Body fontWeight={600} fontSize={36}>
                  {stakingInfo?.userInfos[lockIndex]?.earnedAmount.toSignificant(6)}
                </ThemedText.Body>
                <ThemedText.Body>
                  <Trans>Unclaimed CRN</Trans>
                </ThemedText.Body>
              </AutoColumn>
            )}
            {(doubleRewardsOn || noCrnRewards) && (
              <AutoColumn justify="center" gap="md">
                <ThemedText.Body fontWeight={600} fontSize={36}>
                  {stakingInfo?.doubleRewardAmount?.toSignificant(6)}
                </ThemedText.Body>
                <ThemedText.Body>
                  <Trans>Unclaimed</Trans> {doubleRewardToken.symbol}
                </ThemedText.Body>
              </AutoColumn>
            )}
            <ThemedText.SubHeader style={{ textAlign: 'center' }}>
              <Trans>When you claim without withdrawing your liquidity remains in the mining pool.</Trans>
            </ThemedText.SubHeader>
          </ContentWrapper>
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onClaimReward}>
            {error ?? <Trans>Claim</Trans>}
          </ButtonError>
        </div>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <ThemedText.Body fontSize={20}>
              <Trans>Claiming {stakingInfo?.userInfos[lockIndex]?.earnedAmount.toSignificant(6)}</Trans>
            </ThemedText.Body>
            {(doubleRewardsOn || noCrnRewards) && (
              <ThemedText.Body fontSize={20}>
                <Trans>Claiming</Trans> {stakingInfo?.doubleRewardAmount?.toSignificant(4)} {doubleRewardToken.symbol}
              </ThemedText.Body>
            )}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <ThemedText.LargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.LargeHeader>
            <ThemedText.Body fontSize={20}>
              <Trans>Claimed CRN!</Trans>
            </ThemedText.Body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
