import { BIG_INT_ZERO } from 'constants/misc'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
// simport { Suspense } from 'react'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'

import { useFarms } from '../../state/stake/user-farms'
import { ThemedText } from '../../theme'
import Card from '../Card'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
// import FarmsPortfolio from './FarmsPortfolio'

const StyledCard = styled(Card)`
  // background: ${({ theme }) => `radial-gradient(farthest-corner at 0% 0%, ${theme.primary1} 0%, transparent 70%)`};
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
  flex: 1 1 auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
        min-height: 150px;
  `};
`

const SundialContainer = styled.div`
  position: absolute;
  right: 0px;
  max-height: 200px;
  max-width: 240px;
  overflow: hidden;
  & > * {
    object-position: 24px 16px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
        max-height: 90px;
        & > * {
            height: 200px;
            width: 150px;
            object-position: 24px 16px;
        }
  `};
`

const StyledAutoColumn = styled(AutoColumn)`
  padding-top: 1rem;
  flex: 1;
  max-width: 80%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        max-width: 100%;
  `};
`

export default function FarmBanner() {
  const { account } = useActiveWeb3React()
  const [darkMode] = useDarkModeManager()

  const farmTVL = useFarms().reduce(
    (acc: any, farm: any) => JSBI.add(acc, JSBI.BigInt(farm.totalStakedInUSD)),
    BIG_INT_ZERO
  )
  const farmTVLFriendly = JSBI.GE(farmTVL, BIG_INT_ZERO)
    ? `$${farmTVL.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : '-'
  return (
    <StyledCard>
      <AutoRow justifyContent="space-between" style={{ alignItems: 'flex-start', position: 'relative' }}>
        <StyledAutoColumn>
          <ThemedText.LargeHeader>Earn by providing liquidity with farms</ThemedText.LargeHeader>
          {/* <ThemedText.SubHeader marginTop="1rem">TVL: {farmTVLFriendly}</ThemedText.SubHeader> */}
        </StyledAutoColumn>
      </AutoRow>
    </StyledCard>
  )
}

// <Suspense fallback={<div />}>{account && <FarmsPortfolio />}</Suspense>
