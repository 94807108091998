import { Currency } from '@uniswap/sdk-core'
import { CSSProperties } from 'react'
import styled from 'styled-components/macro'

import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: grid;
  flex-direction: row;
  flex: 0 1 auto;
  margin-left: ${({ sizeraw, margin }) => margin && (sizeraw / 3 + 8).toString() + 'px'};
  width: ${({ sizeraw }) => (sizeraw * 1.4).toString() + 'px'};
  height: ${({ sizeraw }) => (sizeraw * 1.4).toString() + 'px'};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'first second'
    'third fourth';
`

interface TetraCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
  currency2?: Currency
  currency3?: Currency
  style?: CSSProperties
}

const FirstLogo = styled(CurrencyLogo)`
  grid-area: first;
  z-index: 2;
`
const SecondLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  grid-area: second;

  left: ${({ sizeraw }) => '-' + (sizeraw * 0.65).toString() + 'px'} !important;
`
const ThirdLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  grid-area: third;

  bottom: ${({ sizeraw }) => '-' + (sizeraw * 0.65).toString() + 'px'} !important;
`
const FourthLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  grid-area: fourth;

  left: ${({ sizeraw }) => '-' + (sizeraw * 0.65).toString() + 'px'} !important;
  bottom: ${({ sizeraw }) => '-' + (sizeraw * 0.65).toString() + 'px'} !important;
`
export default function TetraCurrencyLogo({
  currency0,
  currency1,
  currency2,
  currency3,
  size = 16,
  margin = false,
  style,
}: TetraCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin} style={{ ...style }}>
      {currency3 && <FourthLogo currency={currency3} size={(size / 1.75).toString() + 'px'} sizeraw={size} />}
      {currency2 && <ThirdLogo currency={currency2} size={(size / 1.75).toString() + 'px'} sizeraw={size} />}
      {currency0 && <SecondLogo currency={currency1} size={(size / 1.75).toString() + 'px'} sizeraw={size} />}
      {currency1 && <FirstLogo currency={currency0} size={(size / 1.75).toString() + 'px'} />}
    </Wrapper>
  )
}
