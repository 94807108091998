import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

import { BIG_INT_ZERO } from '../../constants/misc'
import { useTotalStakedInPool } from '../../data/TotalStakedInPool'

type Props = {
  lpToken?: Token
  userLPStakedAmount?: CurrencyAmount<Token> | null
  totalPoolAmountUSD?: number
}

export default function useUserFarmStatistics({ lpToken, userLPStakedAmount, totalPoolAmountUSD }: Props) {
  const totalStakedInPool = useTotalStakedInPool(lpToken)
  if (
    totalStakedInPool == null ||
    lpToken == null ||
    userLPStakedAmount == null ||
    totalPoolAmountUSD == null ||
    userLPStakedAmount.equalTo(BIG_INT_ZERO)
  ) {
    return null
  }

  const userLPShare = userLPStakedAmount.divide(totalStakedInPool.divide(JSBI.BigInt(10 ** 18))) // @TODO: barenfels Check this decimals problem
  const userLPAmountUSD = userLPShare?.multiply(JSBI.BigInt(totalPoolAmountUSD))
  const userLPAmountUSDFormatted =
    userLPAmountUSD != null
      ? `$${userLPAmountUSD
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      : null

  return {
    totalStakedInPool,
    userLPShare,
    userLPAmountUSD,
    userLPAmountUSDFormatted,
  }
}
