import { TransactionResponse } from '@ethersproject/providers'
// eslint-disable-next-line no-restricted-imports
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import Row, { RowBetween } from 'components/Row'
import { DEFAULT_FALLBACK_CHAIN } from 'constants/chains'
import { CRN } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useMasterChefV2Contract } from 'state/stake/hooks-sushi'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled from 'styled-components/macro'

import { AutoColumn } from '../../components/Column'
import FarmBanner from '../../components/earn/FarmBanner'
import FarmPoolCard from '../../components/earn/FarmPoolCard'
import { StakingCrn } from '../../state/stake/constants'
import { useFarms, useSuperFarms } from '../../state/stake/user-farms'
import { useDarkModeManager, useIsFilterActiveFarms, useToggleFilterActiveFarms } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { isTokenAmountPositive } from '../../utils/pools'
import { DataRow, PoolSection } from './styled'

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;
`

const SimpleWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bg0};
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

const BannerWrapper = styled(SimpleWrapper)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
  `}
`

const SimpleWrapperResponsive = styled(SimpleWrapper)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
  `}
`

const ResponsiveRowBetween = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column
  `}
`

const ResponsiveButtonContainer = styled.div`
  flex: 1 0 auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%
  `}
`

const TextRow = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 2rem 1rem;
    justify-content: center;
  `}
`
const SundialContainer = styled.div`
  position: static;
  right: 0px;
  overflow: hidden;
  height: 275px;
  width: 275x;
  & > * {
    height: 275px;
    width: 275x;
    object-fit: cover;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const StyledBreak = styled.br`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `}
`
enum SortingType {
  liquidity = 'Liquidity',
  totalApr = 'Total APR',
  default = 'Default',
}

type SearchableTokenProps = { symbol: string | undefined; name: string | undefined; address: string }

const MemoizedFarmBanner = React.memo(FarmBanner)
const MemoizedFarmPoolCard = React.memo(FarmPoolCard)

export default function Earn() {
  const { chainId, account } = useActiveWeb3React()
  const [darkMode] = useDarkModeManager()
  const addTransaction = useTransactionAdder()

  const stakingContractv2 = useMasterChefV2Contract()

  const allFarmArrs = useFarms()
  const superFarms = useSuperFarms()
  const [cardInfos, setCardInfos] = useState(
    allFarmArrs.reduce((acc, farm) => {
      return {
        ...acc,
        [farm.ID]: {
          apr: 0,
          apr2: 0,
          totalStakedInUSD: 0,
        },
      }
    }, [])
  )

  /*useEffect(() => {
    setCardInfos(
      allFarmArrs.reduce((acc, farm) => {
        return {
          ...acc,
          [farm.ID]: {
            apr: 0,
            apr2: 0,
            totalStakedInUSD: 0,
          },
        }
      })
    )
  }, [allFarmArrs])*/

  const allFarmsPending = [...allFarmArrs, ...superFarms].reduce((a: { [k: string]: CurrencyAmount<Token> }, b) => {
    if (!b.noCrnRewards) {
      if (a[CRN[chainId ?? DEFAULT_FALLBACK_CHAIN].address]) {
        a[CRN[chainId ?? DEFAULT_FALLBACK_CHAIN].address] = a[CRN[chainId ?? DEFAULT_FALLBACK_CHAIN].address].add(
          b.earnedAmount
        )
      } else {
        a[CRN[chainId ?? DEFAULT_FALLBACK_CHAIN].address] =
          b.earnedAmount ?? CurrencyAmount.fromRawAmount(CRN[chainId ?? DEFAULT_FALLBACK_CHAIN], 0)
      }
    }
    if (b.doubleRewards) {
      if (a[b.doubleRewardToken.address]) {
        a[b.doubleRewardToken.address] = a[b.doubleRewardToken.address].add(b.doubleRewardAmount)
      } else {
        a[b.doubleRewardToken.address] = b.doubleRewardAmount ?? CurrencyAmount.fromRawAmount(b.doubleRewardToken, 0)
      }
    }
    return a
  }, {})

  const filteredFarmsPending = Object.keys(allFarmsPending)
    .filter((key) => +allFarmsPending[key].toExact() > 0)
    .reduce((a: { [k: string]: CurrencyAmount<Token> }, b) => {
      a[b] = allFarmsPending[b]
      return a
    }, {})
  const hasPendingRewards = Object.keys(filteredFarmsPending).length > 0 && filteredFarmsPending.constructor === Object
  const hasPendingCrn = !!filteredFarmsPending[CRN[chainId ?? DEFAULT_FALLBACK_CHAIN].address]

  const POOLS_ORDER = [0, 1, 2]
  const LEGACY_POOLS: any[] = []

  const toggleActiveFarms = useToggleFilterActiveFarms()
  const activeFarmsFilter = useIsFilterActiveFarms()

  const [sortBy, setSortBy] = useState<SortingType>(SortingType.default)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [sortDescending, setSortDescending] = useState<boolean>(true)

  const getSortedFarms = () => {
    switch (sortBy) {
      case SortingType.default:
        return [...allFarmArrs, ...superFarms]
      case SortingType.liquidity:
        return sortDescending
          ? farmArrs.sort((a, b) =>
              (cardInfos as any)[a.ID].totalStakedInUSD < (cardInfos as any)[b.ID].totalStakedInUSD ? 1 : -1
            )
          : farmArrs.sort((a, b) =>
              (cardInfos as any)[a.ID].totalStakedInUSD > (cardInfos as any)[b.ID].totalStakedInUSD ? 1 : -1
            )
      case SortingType.totalApr:
        return sortDescending
          ? farmArrs.sort((a, b) =>
              (cardInfos as any)[a.ID].apr + (cardInfos as any)[a.ID].apr2 <
              (cardInfos as any)[b.ID].apr + (cardInfos as any)[b.ID].apr2
                ? 1
                : -1
            )
          : farmArrs.sort((a, b) =>
              (cardInfos as any)[a.ID].apr + (cardInfos as any)[a.ID].apr2 >
              (cardInfos as any)[b.ID].apr + (cardInfos as any)[b.ID].apr2
                ? 1
                : -1
            )
    }
  }

  const farmArrs = [...allFarmArrs, ...superFarms].filter((farm) => !LEGACY_POOLS.includes(farm.ID))
  const farmArrsInOrder = useMemo(() => getSortedFarms(), [sortBy, farmArrs, superFarms])

  const nonDualRewardPools = farmArrsInOrder.filter((farm) => farm && !farm.doubleRewards && !farm.noCrnRewards)
  const [currentFarms, setCurrentFarms] = useState<StakingCrn[]>(nonDualRewardPools)

  const legacyFarmArrsInOrder = allFarmArrs.filter((farm) => LEGACY_POOLS.includes(farm.ID))
  const dualRewardPools = farmArrsInOrder.filter((farm) => farm.doubleRewards)
  const nonCrnFarms = farmArrsInOrder.filter((farm) => farm.noCrnRewards)

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = event.target.value.toUpperCase()
    setSearchQuery(input)
  }

  const handleSort = (sortingType: SortingType) => {
    if (sortingType === sortBy) {
      setSortDescending(!sortDescending)
    } else {
      setSortBy(sortingType)
    }
  }

  const farmTokensIncludesQuery = ({ symbol, name, address }: SearchableTokenProps, query: string) => {
    return (
      symbol?.toUpperCase().includes(query) ||
      name?.toUpperCase().includes(query) ||
      (query.length > 5 && address?.toUpperCase().includes(query))
    )
  }

  const filterFarms = (farms: StakingCrn[], query: string) => {
    const farmsToFilter = activeFarmsFilter ? farms.filter((farm) => isTokenAmountPositive(farm.stakedAmount)) : farms

    return farmsToFilter.filter(
      (farm) =>
        farm.tokens.some(({ symbol, name, address }) => farmTokensIncludesQuery({ symbol, name, address }, query)) ||
        (query.length > 5 && farm.lpAddress.toUpperCase().includes(query))
    )
  }

  const filteredFarms = useMemo(
    () => filterFarms(currentFarms, searchQuery),
    [currentFarms, searchQuery, activeFarmsFilter, sortBy]
  )

  useEffect(() => {
    const farmsToCompare = searchQuery.length || activeFarmsFilter ? farmArrsInOrder : nonDualRewardPools

    if (!isEqual(currentFarms, farmsToCompare)) {
      setCurrentFarms(farmsToCompare)
    }
  }, [farmArrs])

  const renderSortArrow = () => {
    return sortDescending ? <ChevronDown size={15} /> : <ChevronUp size={15} />
  }

  async function onClaimAllReward() {
    if (stakingContractv2 && hasPendingRewards) {
      await stakingContractv2
        .collectAllPoolRewards()
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.CLAIM,
            recipient: account!,
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  return (
    <PageWrapper gap="lg" justify="center">
      {!searchQuery.length && !activeFarmsFilter && !!dualRewardPools.length && (
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <DataRow style={{ alignItems: 'baseline' }}>
            <ThemedText.MediumHeader style={{ marginTop: '0.5rem' }}>Dual Rewards Pools</ThemedText.MediumHeader>
          </DataRow>

          <PoolSection>
            {dualRewardPools.map((farm) => (
              <MemoizedFarmPoolCard key={farm.ID} farm={farm} />
            ))}
          </PoolSection>
        </AutoColumn>
      )}
      {!!filterFarms.length && (
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <PoolSection>
            {filteredFarms.map((farm) => (
              <MemoizedFarmPoolCard key={farm.ID + '-filter'} farm={farm} />
            ))}
          </PoolSection>
        </AutoColumn>
      )}
      {!searchQuery.length && !activeFarmsFilter && !!nonCrnFarms.length && (
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <DataRow style={{ alignItems: 'baseline' }}>
            <ThemedText.MediumHeader style={{ marginTop: '0.5rem' }}>Ecosystem Pools</ThemedText.MediumHeader>
          </DataRow>

          <PoolSection>
            {nonCrnFarms.map((farm) => (
              <MemoizedFarmPoolCard key={farm.ID + '-nocrn'} farm={farm} />
            ))}
          </PoolSection>
        </AutoColumn>
      )}
      {!searchQuery.length && !activeFarmsFilter && !!legacyFarmArrsInOrder.length && (
        <AutoColumn gap="lg" style={{ width: '100%' }}>
          <DataRow style={{ alignItems: 'baseline' }}>
            <ThemedText.MediumHeader style={{ marginTop: '0.5rem' }}>Legacy Pools</ThemedText.MediumHeader>
          </DataRow>
          <PoolSection>
            {legacyFarmArrsInOrder.map((farm) => (
              <MemoizedFarmPoolCard key={farm.ID + '-legacy'} farm={farm} />
            ))}
          </PoolSection>
        </AutoColumn>
      )}
    </PageWrapper>
  )
}
