import { RouteComponentProps } from 'react-router-dom'
import { STAKING } from 'state/stake/constants'

import ManageFarm from './ManageFarm'
import ManageSuperFarm from './ManageSuperFarm'

export default function Manage({
  match: {
    params: { poolId },
  },
  history,
}: RouteComponentProps<{ poolId: string }>) {
  const farm = STAKING.find((farm) => farm.ID === poolId)

  if (!farm) {
    history.replace('/farms')
    return <></>
  }

  if (!farm.isLegacy) {
    return <ManageSuperFarm poolId={poolId} />
  }

  return <ManageFarm poolId={poolId} />
}
