import { Currency, Price, Token } from '@uniswap/sdk-core'

export function getAPR(
  price: Price<Currency, Token> | undefined,
  rewardRatePerSec: number,
  totalStakedInUSD: number
): number {
  if (totalStakedInUSD === 0 || !price) {
    return 0
  }
  const totalYearlyRewards = rewardRatePerSec * 3600 * 24 * 365
  return ((totalYearlyRewards * Number(price.toFixed())) / totalStakedInUSD) * 100
}
