/**
 * List of all the networks supported by the Cronus Interface
 */
export enum SupportedChainId {
  MAINNET = 9001,
  EVMOS_TESTNET = 9000,
  RINKEBY = 4,
  NULL_CHAIN = 0,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'evmos',
  [SupportedChainId.EVMOS_TESTNET]: 'evmos_testnet',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.NULL_CHAIN]: 'arbitrum_rinkeby',
}

export const DEFAULT_FALLBACK_CHAIN = SupportedChainId.MAINNET

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [SupportedChainId.NULL_CHAIN]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.EVMOS_TESTNET,
  SupportedChainId.RINKEBY,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [SupportedChainId.NULL_CHAIN] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]
