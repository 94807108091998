import { TransactionResponse } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ClickableText } from 'pages/Pool/styleds'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { SuperFarmResult } from 'state/stake/user-farms'
import styled from 'styled-components/macro'

import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useCLP from '../../hooks/useCLP'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { StakingCrn } from '../../state/stake/constants'
import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { useMasterChefV2Contract } from '../../state/stake/hooks-sushi'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, ThemedText } from '../../theme'
import { formatCurrencyAmount } from '../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import CurrencyInputPanel from '../CurrencyInputPanel'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import Row, { RowBetween } from '../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const StakeClickableText = styled(ClickableText)<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.primary1 : theme.bg5)};
`

interface ManageModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingCrn | SuperFarmResult
  userLiquidityUnstaked: CurrencyAmount<Token> | undefined
  unstake?: boolean
}

export default function ManageModal({
  isOpen,
  onDismiss,
  stakingInfo,
  userLiquidityUnstaked,
  unstake = false,
}: ManageModalProps) {
  const { account } = useActiveWeb3React()

  // track and parse user input
  const [typedStakeValue, setTypedStakeValue] = useState('')
  const [typedUnstakeValue, setTypedUnstakeValue] = useState('')
  const [isUnstaking, setIsUnstaking] = useState(unstake)
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedStakeValue,
    stakingInfo.stakedAmount?.currency,
    userLiquidityUnstaked
  )

  useEffect(() => {
    if (unstake !== isUnstaking) {
      setIsUnstaking(unstake)
    }
  }, [unstake])

  let unstakeError: ReactNode | undefined
  if (!account) {
    unstakeError = <Trans>Connect a wallet</Trans>
  }
  if (!stakingInfo?.stakedAmount) {
    unstakeError = unstakeError ?? <Trans>Enter an amount</Trans>
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = new Pair(
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[0], '0'),
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[1], '0')
  )
  const lpToken = useCLP({
    lpAddress: stakingInfo.lpAddress,
    token0: stakingInfo.tokens[0],
    token1: stakingInfo.tokens[1],
  })

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)

  const stakingContractv2 = useMasterChefV2Contract()
  const doubleRewardsOn = stakingInfo.doubleRewards
  const doubleRewardToken = stakingInfo.doubleRewardToken
  const noCrnRewards = stakingInfo.noCrnRewards
  const maxUnstakeAmountInput = stakingInfo.stakedAmount ? stakingInfo.stakedAmount : undefined

  async function onStake() {
    setAttempting(true)
    if (stakingContractv2 && parsedAmount && deadline) {
      await stakingContractv2
        .deposit(stakingInfo.poolId, parseUnits(typedStakeValue))
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
            token0Address: stakingInfo.tokens[0].address,
            token1Address: stakingInfo.tokens[1].address,
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  async function onWithdraw() {
    setAttempting(true)
    if (stakingContractv2 && stakingInfo?.stakedAmount) {
      await stakingContractv2
        .withdraw(stakingInfo.poolId, parseUnits(typedUnstakeValue))
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.WITHDRAW_LIQUIDITY_STAKING,
            token0Address: stakingInfo.tokens[0].address,
            token1Address: stakingInfo.tokens[1].address,
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  function handleStaking() {
    setIsUnstaking(false)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  function handleUnstaking() {
    setIsUnstaking(true)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (typedValue: string) => {
      setSignatureData(null)
      if (!isUnstaking) {
        setTypedStakeValue(typedValue)
      } else {
        setTypedUnstakeValue(typedValue)
      }
    },
    [isUnstaking]
  )

  // used for max input button
  const maxStakeAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxStakeAmountInput && parsedAmount?.equalTo(maxStakeAmountInput))
  const atMaxUnstakeAmount = Boolean(
    maxUnstakeAmountInput && tryParseCurrencyAmount(typedUnstakeValue, lpToken)?.equalTo(maxUnstakeAmountInput)
  )
  const handleMax = useCallback(() => {
    if (!isUnstaking && maxStakeAmountInput) {
      onUserInput(maxStakeAmountInput.toExact())
    }
    if (isUnstaking && maxUnstakeAmountInput) {
      onUserInput(maxUnstakeAmountInput.toExact())
    }
  }, [isUnstaking, maxStakeAmountInput, maxUnstakeAmountInput, onUserInput])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={100}>
      {!attempting && !hash && (
        <div style={{ width: '100%' }}>
          <ContentWrapper gap="lg">
            <RowBetween>
              <ThemedText.MediumHeader>
                {!isUnstaking ? <Trans>Deposit</Trans> : <Trans>Withdraw</Trans>}
              </ThemedText.MediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            {!(stakingInfo.stakedAmount?.equalTo(JSBI.BigInt(0)) || userLiquidityUnstaked?.equalTo(JSBI.BigInt(0))) && (
              <RowBetween style={{ justifyContent: 'end' }}>
                <Row>
                  <StakeClickableText selected={!isUnstaking} style={{ paddingRight: '10px' }} onClick={handleStaking}>
                    Stake
                  </StakeClickableText>
                  <StakeClickableText selected={isUnstaking} onClick={handleUnstaking}>
                    Unstake
                  </StakeClickableText>
                </Row>
              </RowBetween>
            )}
            <CurrencyInputPanel
              value={!isUnstaking ? typedStakeValue : typedUnstakeValue}
              onUserInput={onUserInput}
              onMax={handleMax}
              showMaxButton={!isUnstaking ? !atMaxAmount : !atMaxUnstakeAmount}
              currency={lpToken}
              pair={dummyPair}
              label={''}
              renderBalance={(amount) =>
                !isUnstaking ? (
                  <Trans>Available to deposit: {formatCurrencyAmount(amount, 4)} </Trans>
                ) : (
                  <Trans>Available to withdraw: {formatCurrencyAmount(maxUnstakeAmountInput, 4)}</Trans>
                )
              }
              id="stake-liquidity-token"
            />
            <ThemedText.SubHeader style={{ textAlign: 'center' }}>
              {!isUnstaking ? (
                <>
                  {stakingInfo.stakedAmount?.equalTo(JSBI.BigInt(0)) ? (
                    <></>
                  ) : (
                    <Trans>When you deposit your CRN is claimed automatically</Trans>
                  )}
                </>
              ) : (
                <>
                  <Trans>When you withdraw your CRN is claimed automatically</Trans>
                </>
              )}
            </ThemedText.SubHeader>
            {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <ThemedText.black fontWeight={600}>{t('earn.weeklyRewards')}</ThemedText.black>
            </div>

            <ThemedText.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              {t('earn.pngWeek')}
            </ThemedText.black>
          </HypotheticalRewardRate> */}
          </ContentWrapper>
          {!isUnstaking ? (
            <RowBetween>
              <ButtonConfirmed
                mr="0"
                onClick={approveCallback}
                confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
                disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
              >
                <Trans>Approve</Trans>
              </ButtonConfirmed>
              <ButtonError
                disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
                error={!!error && !!parsedAmount}
                onClick={onStake}
              >
                {error ?? <Trans>Deposit</Trans>}
              </ButtonError>
            </RowBetween>
          ) : (
            <ButtonError
              disabled={!!unstakeError || !typedUnstakeValue}
              error={!!unstakeError && !!stakingInfo?.stakedAmount}
              onClick={onWithdraw}
            >
              {unstakeError ?? <Trans>Withdraw</Trans>}
            </ButtonError>
          )}
        </div>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          {!isUnstaking ? (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.LargeHeader>
                <Trans>Depositing Liquidity</Trans>
              </ThemedText.LargeHeader>
              <ThemedText.Body fontSize={20}>
                <Trans>{parsedAmount?.toSignificant(4)} CLP</Trans>
              </ThemedText.Body>
            </AutoColumn>
          ) : (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.Body fontSize={20}>
                <Trans>Withdrawing {typedUnstakeValue} CLP</Trans>
              </ThemedText.Body>
              <ThemedText.Body fontSize={20}>
                <Trans>Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} CRN</Trans>
              </ThemedText.Body>
              {(doubleRewardsOn || noCrnRewards) && (
                <ThemedText.Body fontSize={20}>
                  <Trans>Claiming</Trans> {stakingInfo?.doubleRewardAmount?.toSignificant(4)} {doubleRewardToken.symbol}
                </ThemedText.Body>
              )}
            </AutoColumn>
          )}
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <ThemedText.LargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.LargeHeader>
            {!isUnstaking ? (
              <ThemedText.Body fontSize={20}>
                <Trans>Deposited {parsedAmount?.toSignificant(4)} CLP</Trans>
              </ThemedText.Body>
            ) : (
              <>
                <ThemedText.Body fontSize={20}>
                  <Trans>Withdrew CLP!</Trans>
                </ThemedText.Body>
                <ThemedText.Body fontSize={20}>
                  <Trans>Claimed CRN!</Trans>
                </ThemedText.Body>
              </>
            )}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
