import { NativeCurrency, Token } from '@uniswap/sdk-core'

import { SupportedChainId } from './chains'
import { ZERO_ADDRESS } from './misc'

// TODO edit official initial token list

export const WBTC: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0xF80699Dc594e00aE7bA200c7533a07C1604A106D',
    8,
    'madWBTC',
    'Wrapped BTC'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x57ec4cC022051a040bf953A13e167b8161B0C366',
    18,
    'WBTC',
    'Wrapped BTC'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x85Dea6042da8Cf5B210E97797ba56594B9BfA914',
    18,
    'WBTC',
    'Wrapped BTC'
  ),
}

export const WETH: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x5842C5532b61aCF3227679a8b1BD0242a41752f2',
    18,
    'madWETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    ZERO_ADDRESS,
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.RINKEBY, ZERO_ADDRESS, 18, 'WETH', 'Wrapped Ether'),
}

export const USDC: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x51e44FfaD5C2B122C8b635671FCC8139dc636E82',
    6,
    'USDC',
    'USD Coin | Celer'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x2b60d7059A177348312d8aF0ec7C4a41EF26119D',
    18,
    'USDC',
    'tUSD//C'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x0f442e514a3f96bb260e719a88523b082eb7498c',
    18,
    'USDC',
    'tUSD//C'
  ),
}

export const ceUSDC: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0xe46910336479F254723710D57e7b683F3315b22B',
    6,
    'ceUSDC',
    'USD Coin | Celer cBridge'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x2b60d7059A177348312d8aF0ec7C4a41EF26119D',
    18,
    'USDC',
    'tUSD//C'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x0f442e514a3f96bb260e719a88523b082eb7498c',
    18,
    'USDC',
    'tUSD//C'
  ),
}

export const multiUSDC: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
    6,
    'multiUSDC',
    'USD Coin | Multichain'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x2b60d7059A177348312d8aF0ec7C4a41EF26119D',
    18,
    'USDC',
    'tUSD//C'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x0f442e514a3f96bb260e719a88523b082eb7498c',
    18,
    'USDC',
    'tUSD//C'
  ),
}

export const USDT: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x7FF4a56B32ee13D7D4D405887E0eA37d61Ed919e',
    6,
    'madUSDT',
    'Tether USD'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x2c598b42cb7952e4f906fdeaf74a1ed4c5966592',
    18,
    'USDT',
    'Tether USD'
  ),
}

export const DAI: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x63743ACF2c7cfee65A5E356A4C4A005b586fC7AA',
    18,
    'madDAI',
    'DAI Stablecoin'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x0440d475455436c7B1bf6Ed42E8Fd6299789a65a',
    18,
    'DAI',
    'tDAI'
  ),
}

export const UST: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x0000000000000000000000000000000000000001',
    6,
    'UST',
    'Terra USD'
  ),

  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x9b131b7cC4874843a6d99BF24bBA4d28a540A041',
    18,
    'UST',
    'Terra USD'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x3Dc30d756A1382BF454dD6bDb48b7225CA214bB3',
    18,
    'UST',
    'Terra USD'
  ),
}

export const FRAX: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0xE03494D0033687543a80c9B1ca7D6237F2EA8BD8',
    18,
    'FRAX',
    'Frax'
  ),

  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xaC78A55960D01DB6f08be95952A17b8D0103F3f7',
    18,
    'FRAX',
    'Frax'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0xcAD65DfF50050b175EDe9e9A6A7A1BDd4D08E920',
    18,
    'FRAX',
    'Frax'
  ),
}

export const ATOM: { [chainId: number]: Token } = {
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x41f67a991b687149f96D7C51B83D3Fc4125b6bC1',
    18,
    'ATOM',
    'Cosmos'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0xEbf034361B20592090C916b630DB77cb96B84CdC',
    18,
    'ATOM',
    'Cosmos'
  ),
}

export const OSMO: { [chainId: number]: Token } = {
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xE6c5A550ca454DA95DB0051e4c713da32ed3e2Df',
    18,
    'OSMO',
    'Osmosis'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0xe0BD2B25Bf0F71D4b93849a0fB4b224B9B5F83d6',
    18,
    'OSMO',
    'Osmosis'
  ),
}

export const CRN: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x1488346419FFc85C6D54E71be80a222971fb2240',
    18,
    'CRN',
    'Cronus'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0x8D092da14C492e769436EA538B94637fA9d5A7e7',
    18,
    'CRN',
    'tCronus'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x7182f79225019356FB718cF464AD1A3eB6129429',
    18,
    'CRN',
    'rCronus'
  ),
}

export const aCRN: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0x6e1d38718ee5dbD16D9277C7aeC74060012Ceae2',
    18,
    'aCRN',
    'Cronus Finance'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xF4c8a6431e7404CF6E66E0404df83B18307466ED',
    18,
    'taCRN',
    'taCronus'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0x7182f79225019356FB718cF464AD1A3eB6129429',
    18,
    'CRN',
    'rCronus'
  ),
}

export const PURCHASE_TOKEN_ONE: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, ZERO_ADDRESS, 18, 'PTO', 'purchaseTokenOne'),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xB49A69BD51b213982079cE05DB7680f54F8f8859',
    18,
    'PTO',
    'purchaseTokenOne'
  ),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.MAINNET, ZERO_ADDRESS, 18, 'PTO', 'purchaseTokenOne'),
}

export const PURCHASE_TOKEN_TWO: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(SupportedChainId.MAINNET, ZERO_ADDRESS, 6, 'PTT', 'purchaseTokenTwo'),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xf07af31b9269C60C96BF8AAB0959645FACe9Da8a',
    6,
    'PTT',
    'purchaseTokenTwo'
  ),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.MAINNET, ZERO_ADDRESS, 6, 'PTT', 'purchaseTokenTwo'),
}

export const SALECRN: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0xc697f221b1b711ff29dde2dc21f0582cc25f7c84',
    18,
    'CRN',
    'Cronus'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xa6c71F88944e632D9153708FC10309C1Cd43aE00',
    18,
    'sCRN',
    'sale Cronus'
  ),
  [SupportedChainId.RINKEBY]: new Token(SupportedChainId.MAINNET, ZERO_ADDRESS, 18, 'sCRN', 'sale Cronus'),
}

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    SupportedChainId.MAINNET,
    '0xD4949664cD82660AaE99bEdc034a0deA8A0bd517',
    18,
    'WEVMOS',
    'Wrapped Evmos'
  ),
  [SupportedChainId.EVMOS_TESTNET]: new Token(
    SupportedChainId.EVMOS_TESTNET,
    '0xcF5ef8d007a616066e5eaEa0916592374a0F478D', // @TODO: barenfels Test contract, non proprietary, remember to change
    18,
    'WEVMOS',
    'Wrapped Evmos'
  ),
  [SupportedChainId.RINKEBY]: new Token(
    SupportedChainId.RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WEVMOS',
    'Wrapped Evmos'
  ),
}

export class ExtendedEther extends NativeCurrency {
  protected constructor(chainId: number) {
    super(chainId, 18, 'EVMOS', 'Evmos')
  }
  public get wrapped(): Token {
    if (
      this.chainId in WRAPPED_NATIVE_CURRENCY &&
      (this.chainId === SupportedChainId.MAINNET ||
        this.chainId === SupportedChainId.EVMOS_TESTNET ||
        this.chainId === SupportedChainId.RINKEBY)
    )
      return WRAPPED_NATIVE_CURRENCY[this.chainId]
    throw new Error('Unsupported chain ID')
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
  public equals(other: NativeCurrency | Token): boolean {
    return other.isNative && other.chainId === this.chainId
  }
}

const cachedNativeCurrency: { [chainId: number]: ExtendedEther } = {}
export function nativeOnChain(chainId: number): ExtendedEther {
  return (
    cachedNativeCurrency[chainId] ??
    (cachedNativeCurrency[chainId] = //isMatic(chainId)
      //? new MaticNativeCurrency(chainId) :
      ExtendedEther.onChain(chainId))
  )
}

export const TST = new Token(
  SupportedChainId.EVMOS_TESTNET,
  '0x5AC8D90E1Ea53bF084c78deb1B62e4b92D6Fc9B8',
  18,
  'TST',
  'Test Token'
)
