import { ArrowsVertical20 } from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import { MouseoverTooltip } from 'components/Tooltip'
import { POOLS_MAP } from 'constants/tetrapools'
import { parseUnits } from 'ethers/lib/utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, CheckCircle, HelpCircle } from 'react-feather'
import ReactGA from 'react-ga4'
import { Text } from 'rebass'
import { TradeState } from 'state/routing/types'
import { useDerivedTetraSwapInfo, useTetraContract } from 'state/tetraswap/hooks'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { ThemeContext } from 'styled-components/macro'
import { calculateSlippageAndParse } from 'utils/calculateSlippageAndParse'
import { currencyId } from 'utils/currencyId'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyLogo from '../../components/CurrencyLogo'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import ConfirmSwapModal from '../../components/swap/ConfirmTetraSwapModal'
import { ArrowWrapper, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20Permit, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useSwapActionHandlers, useTetraParamFromURLSearch, useTetraSwapState } from '../../state/swap/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { warningSeverity } from '../../utils/prices'

const AlertWrapper = styled.div`
  max-width: 460px;
  width: 100%;
`

export default function Swap({ poolId }: { poolId: string }) {
  const { account } = useActiveWeb3React()
  useTetraParamFromURLSearch(poolId)

  const { poolTokens } = POOLS_MAP[poolId]
  const mappedTokens: { [address: string]: Token } = poolTokens.reduce((a, t) => {
    return { ...a, [t.address]: t }
  }, {})

  const swapContract = useTetraContract(poolId)
  const addTransaction = useTransactionAdder()

  // dismiss warning if all imported tokens are in active lists

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const deadline = useTransactionDeadline()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // swap state
  const swapState = useTetraSwapState()

  const { independentField, typedValue, recipient } = swapState

  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedTetraSwapInfo(poolId)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade, TradeState.LOADING === tradeState, TradeState.SYNCING === tradeState],
    [trade, tradeState]
  )

  const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT])
  const priceImpact = useMemo(
    () => (routeIsSyncing ? undefined : computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)),
    [fiatValueInput, fiatValueOutput, routeIsSyncing]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallback(parsedAmounts[Field.INPUT], swapContract?.address)
  const transactionDeadline = useTransactionDeadline()
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20Permit(parsedAmounts[Field.INPUT], account, transactionDeadline, undefined)

  const handleApprove = useCallback(async () => {
    if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }, [signatureState, gatherPermitSignature, approveCallback])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap

  const handleSwap = useCallback(() => {
    if (
      !swapContract ||
      !trade ||
      !currencies ||
      !currencies[Field.INPUT] ||
      !currencies[Field.OUTPUT] ||
      !currencies[Field.INPUT]?.isToken ||
      !currencies[Field.INPUT]?.isToken
    ) {
      return
    }

    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapContract
      .swap(
        poolTokens.findIndex(
          (el) =>
            el.address ===
            (currencies?.[Field.INPUT]?.isToken
              ? currencies?.[Field.INPUT]?.wrapped.address
              : currencies?.[Field.INPUT]?.symbol)
        ),
        poolTokens.findIndex(
          (el) =>
            el.address ===
            (currencies?.[Field.OUTPUT]?.isToken
              ? currencies?.[Field.OUTPUT]?.wrapped.address
              : currencies?.[Field.OUTPUT]?.symbol)
        ),
        parseUnits(parsedAmounts[Field.INPUT]?.toExact() ?? '0'),
        calculateSlippageAndParse(parsedAmounts[Field.INPUT] ?? '0', allowedSlippage).parsed,
        deadline
      )
      .then((response: any) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash,
        })
        addTransaction(
          response,
          trade.tradeType === TradeType.EXACT_INPUT
            ? {
                type: TransactionType.SWAP,
                tradeType: TradeType.EXACT_OUTPUT,
                inputCurrencyId: currencyId(trade.inputAmount.currency),
                maximumInputCurrencyAmountRaw: trade.minimumAmountOut.quotient.toString(),
                outputCurrencyId: currencyId(trade.outputAmount.currency),
                outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
              }
            : {
                type: TransactionType.SWAP,
                tradeType: TradeType.EXACT_INPUT,
                inputCurrencyId: currencyId(trade.inputAmount.currency),
                inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
                expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
                outputCurrencyId: currencyId(trade.outputAmount.currency),
                minimumOutputCurrencyAmountRaw: trade.maximumAmountIn.quotient.toString(),
              }
        )
      })
      .catch((error: any) => {
        console.error(error)

        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    swapContract,
    trade,
    currencies,
    tradeToConfirm,
    showConfirm,
    poolTokens,
    parsedAmounts,
    allowedSlippage,
    deadline,
    addTransaction,
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    ReactGA.event({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  return (
    <>
      <Wrapper id="swap-page" style={{ padding: '1.25rem' }}>
        <ConfirmSwapModal
          isOpen={showConfirm}
          trade={trade}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          recipient={recipient}
          allowedSlippage={allowedSlippage}
          onConfirm={handleSwap}
          swapErrorMessage={swapErrorMessage}
          onDismiss={handleConfirmDismiss}
        />

        <AutoColumn gap={'sm'}>
          <div style={{ display: 'relative' }}>
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
              }
              value={
                formattedAmounts[Field.INPUT] === '0' && independentField === Field.OUTPUT
                  ? ''
                  : formattedAmounts[Field.INPUT]
              }
              showMaxButton={showMaxButton}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              fiatValue={fiatValueInput ?? undefined}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              customList={mappedTokens}
              id="swap-currency-input"
              loading={independentField === Field.OUTPUT && routeIsSyncing}
              disableNonToken
            />
            <ArrowWrapper clickable>
              <ArrowsVertical20
                onClick={() => {
                  setApprovalSubmitted(false) // reset 2 step UI for approvals
                  onSwitchTokens()
                }}
                color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.text1 : theme.text3}
              />
            </ArrowWrapper>
            <CurrencyInputPanel
              value={
                formattedAmounts[Field.OUTPUT] === '0' && independentField === Field.INPUT
                  ? ''
                  : formattedAmounts[Field.OUTPUT]
              }
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap ? <Trans>To (at least)</Trans> : <Trans>To</Trans>}
              showMaxButton={false}
              hideBalance={false}
              fiatValue={fiatValueOutput ?? undefined}
              priceImpact={priceImpact}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              customList={mappedTokens}
              id="swap-currency-output"
              loading={independentField === Field.INPUT && routeIsSyncing}
              disableNonToken
            />
          </div>

          {recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDown size="16" color={theme.text2} />
                </ArrowWrapper>
                <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                  <Trans>- Remove recipient</Trans>
                </LinkStyledButton>
              </AutoRow>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}
        </AutoColumn>
      </Wrapper>
      {/* !showWrap && userHasSpecifiedInputOutput && (
        <SwapDetailsDropdown
          trade={trade}
          syncing={routeIsSyncing}
          loading={routeIsLoading}
          showInverted={showInverted}
          setShowInverted={setShowInverted}
          allowedSlippage={allowedSlippage}
        />
      ) */}
      <div>
        {swapIsUnsupported ? (
          <ButtonPrimary disabled={true}>
            <ThemedText.Main mb="4px">
              <Trans>Unsupported Asset</Trans>
            </ThemedText.Main>
          </ButtonPrimary>
        ) : !account ? (
          <ButtonPrimary onClick={toggleWalletModal}>
            <Trans>Connect Wallet</Trans>
          </ButtonPrimary>
        ) : showWrap ? (
          <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
            {wrapInputError ? (
              <WrapErrorText wrapInputError={wrapInputError} />
            ) : wrapType === WrapType.WRAP ? (
              <Trans>Wrap</Trans>
            ) : wrapType === WrapType.UNWRAP ? (
              <Trans>Unwrap</Trans>
            ) : null}
          </ButtonPrimary>
        ) : routeNotFound && userHasSpecifiedInputOutput && !routeIsLoading && !routeIsSyncing ? (
          <GreyCard style={{ textAlign: 'center' }}>
            <ThemedText.Main mb="4px">
              <Trans>Insufficient liquidity for this trade.</Trans>
            </ThemedText.Main>
          </GreyCard>
        ) : showApproveFlow ? (
          <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
            <ButtonConfirmed
              onClick={handleApprove}
              disabled={
                approvalState !== ApprovalState.NOT_APPROVED ||
                approvalSubmitted ||
                signatureState === UseERC20PermitState.SIGNED
              }
              width="100%"
              altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
              confirmed={approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED}
            >
              <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <CurrencyLogo
                    currency={currencies[Field.INPUT]}
                    size={'20px'}
                    style={{ marginRight: '8px', flexShrink: 0, color: theme.white, fill: theme.white }}
                  />
                  {/* we need to shorten this string on mobile */}
                  {approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED ? (
                    <Trans>Approved</Trans>
                  ) : (
                    <Trans>Approve {currencies[Field.INPUT]?.symbol}</Trans>
                  )}
                </span>
                {approvalState === ApprovalState.PENDING ? (
                  <Loader stroke="white" />
                ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                  signatureState === UseERC20PermitState.SIGNED ? (
                  <CheckCircle size="19" color={theme.green1} />
                ) : (
                  <MouseoverTooltip
                    text={
                      <Trans>
                        You must give the Cronus smart contracts permission to use your{' '}
                        {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                      </Trans>
                    }
                  >
                    <HelpCircle size="19" color={'white'} style={{ marginLeft: '8px' }} />
                  </MouseoverTooltip>
                )}
              </AutoRow>
            </ButtonConfirmed>
            {approvalState === ApprovalState.APPROVED && (
              <ButtonError
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap()
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined,
                    })
                  }
                }}
                width="100%"
                id="swap-button"
                disabled={
                  !isValid ||
                  routeIsSyncing ||
                  routeIsLoading ||
                  (approvalState !== ApprovalState.APPROVED && signatureState !== UseERC20PermitState.SIGNED)
                }
                error={isValid && priceImpactSeverity > 2}
              >
                <Text fontSize={20} fontWeight={500}>
                  <Trans>Swap</Trans>
                </Text>
              </ButtonError>
            )}
          </AutoRow>
        ) : (
          <ButtonError
            onClick={() => {
              if (isExpertMode) {
                handleSwap()
              } else {
                setSwapState({
                  tradeToConfirm: trade,
                  attemptingTxn: false,
                  swapErrorMessage: undefined,
                  showConfirm: true,
                  txHash: undefined,
                })
              }
            }}
            id="swap-button"
            disabled={!isValid || routeIsSyncing || routeIsLoading}
          >
            <Text fontSize={20} fontWeight={500}>
              <Trans>Swap</Trans>
            </Text>
          </ButtonError>
        )}
        {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </div>
      <SwitchLocaleLink />
    </>
  )
}
