import {
  Alarm32,
  CalendarHeatMap32,
  Hourglass32,
  Locked16,
  Settings20 as ManageIcon,
  Snowflake32,
  Unlocked16,
} from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import Card, { DarkGreyCard } from 'components/Card'
import SuperClaimRewardModal from 'components/earn/SuperClaimRewardModal'
import SuperManageModal from 'components/earn/SuperManageModal'
import { CRN } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { ReactElement, useCallback, useState } from 'react'
import { NULL_POOL } from 'state/stake/constants'
import styled, { useTheme } from 'styled-components/macro'
import { CountUp } from 'use-count-up'

import { ButtonGray, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { CardSection, DataCard } from '../../components/earn/styled'
import { RowBetween } from '../../components/Row'
import useCLP from '../../hooks/useCLP'
import { useColorForToken } from '../../hooks/useColor'
import usePrevious from '../../hooks/usePrevious'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useSuperFarm } from '../../state/stake/user-farms'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ThemedText } from '../../theme'
import { getPairRenderOrder } from '../../utils/pools'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  background-color: ${({ theme }) => theme.bg0};
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px,
    rgb(0 0 0 / 1%) 0px 24px 32px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1rem;
  `};
`

const StyledCardSection = styled(CardSection)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.bg3};
  opacity: ${({ disabled }) => disabled && '0.4'};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: center;
    text-align: center;
  `}
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const ResponsiveRowBetween = styled(RowBetween)`
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: inherit;
  `};
`

const StakeTimeRow = styled(RowBetween)`
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `};
`

const LocksRow = styled(RowBetween)`
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: inherit;
  `};
`

const StyledBottomCard = styled(DataCard)`
  background: transparent;
`

const PoolData = styled(DarkGreyCard)`
  background: transparent;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 110px;
    text-align: center;
  `};
`

const PoolDataRow = styled(PoolData)<{ dim: any | undefined }>`
  color: ${({ dim, theme }) => (dim ? theme.text4 : theme.text1)};
  &:first-child:not(:last-child) {
    border-right: 1px solid #2c2f36;
  }
`

const PoolDataBorder = styled(DarkGreyCard)`
  border: 1px solid ${({ theme }) => theme.bg3};
  background-color: transparent;
  border-radius: 8px;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    border: 0px solid transparent;
    flex: 0 1 47%;
    padding: 0.5rem;
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;
  `};
`

const Wrapper = styled(Card)`
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 100%;
  padding: 0;
`

const VoteCard = styled(DataCard)`
  // background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  // overflow: hidden;
`

const BackgroundColor = styled.span<{ bgColor1: string | null; bgColor2?: string | null }>`
  background: ${({ theme, bgColor1, bgColor2 }) =>
    `linear-gradient(90deg, ${bgColor1 ?? theme.blue1} 0%, ${bgColor2 ?? 'grey'} 90%);`};
  background-size: cover;
  mix-blend-mode: overlay;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
     gap: 0;
   `};
`

export default function ManageSuperFarm({ poolId }: { poolId: string }) {
  const { account } = useActiveWeb3React()
  const theme = useTheme()
  const stakingInfo = useSuperFarm(poolId)

  const {
    doubleRewards,
    doubleRewardAmount,
    earnedAmount,
    inStaging,
    noCrnRewards,
    lpAddress,
    stakedAmount,
    crnStakedAmount,
    tokens,
    totalRewardRate,
    userStakedInUSD,
    totalStakedInUSD,
    doubleRewardToken,
    ratio,
    apr,
    apr2,
    userInfos,
    lockInfos,
  } = stakingInfo || NULL_POOL

  const [selectedLock, setSelectedLock] = useState(0)

  const previousEarnedAmount = usePrevious(earnedAmount)
  const previousDoubleRewardAmount = usePrevious(doubleRewardAmount)
  // get currencies and pair
  const { currency0, currency1, token0, token1 } = getPairRenderOrder(tokens[0], tokens[1])

  const totalStakedInUSDFriendly = totalStakedInUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const totalRewardRateFriendly =
    totalRewardRate < 1 ? totalRewardRate : totalRewardRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // get the color of the token
  const backgroundColor1 = useColorForToken(token0)

  // Only override `backgroundColor2` if it's a dual rewards pool
  const backgroundColor2 = useColorForToken(token1, () => doubleRewards)
  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakedAmount?.currency)
  const userCrnUnstaked = useTokenBalance(account ?? undefined, CRN[tokens[0].chainId])
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showManageModal, setShowManageModal] = useState(false)
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakedAmount || stakedAmount.equalTo(JSBI.BigInt(0))

  const toggleWalletModal = useWalletModalToggle()

  const lpToken = useCLP({ lpAddress, token0, token1 })

  const userLPAmountUsdFormatted = '$' + String(userStakedInUSD).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const poolHandle = `${currency0.symbol}-${currency1.symbol}`

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const handleManageClick = useCallback(() => {
    if (account) {
      setShowManageModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const icons: { [i: number]: ReactElement } = {
    0: <Alarm32 style={{ margin: '0.75rem 0' }} />,
    1: <Hourglass32 style={{ margin: '0.75rem 0' }} />,
    2: <CalendarHeatMap32 style={{ margin: '0.75rem 0' }} />,
    3: <Snowflake32 style={{ margin: '0.75rem 0' }} />,
  }

  return (
    <PageWrapper gap="lg" justify="center">
      <AutoColumn gap="sm" style={{ width: '100%', padding: '1rem 1.25rem 0.5rem 1.25rem' }}>
        <RowBetween style={{ gap: '24px' }}>
          <ThemedText.Black fontWeight={500} fontSize={20} style={{ marginRight: '8px' }}>
            <Trans>{poolHandle} Superfarm</Trans>
          </ThemedText.Black>

          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        </RowBetween>
      </AutoColumn>
      <div style={{ display: 'grid', gridRowGap: '24px', padding: '0 1rem', width: '100%' }}>
        <DataRow style={{ gap: '24px' }}>
          <PoolData>
            <AutoColumn gap="sm">
              <ThemedText.SubHeader>
                <Trans>Total staked</Trans>
              </ThemedText.SubHeader>
              <ThemedText.Body fontSize={24} fontWeight={500}>
                {`$${totalStakedInUSDFriendly}`}
              </ThemedText.Body>
            </AutoColumn>
          </PoolData>
          <PoolData>
            <AutoColumn gap="sm">
              <ThemedText.SubHeader>
                <Trans>Ratio</Trans>
              </ThemedText.SubHeader>
              <ThemedText.Body fontSize={20} fontWeight={500}>
                {`${ratio.toSignificant()} CLP`} : 1 CRN
              </ThemedText.Body>
            </AutoColumn>
          </PoolData>
        </DataRow>
        <DataRow style={{ gap: '24px' }}>
          <PoolData>
            <AutoColumn gap="sm">
              <ThemedText.SubHeader>
                <Trans>Your staked liquidity</Trans>
              </ThemedText.SubHeader>
              <ThemedText.Body fontSize={24} fontWeight={500}>
                {userLPAmountUsdFormatted ?? '$0'}
              </ThemedText.Body>
            </AutoColumn>
          </PoolData>
          <PoolData>
            <AutoColumn gap="sm">
              <ThemedText.SubHeader>
                <Trans>Your unclaimed CRN</Trans>
              </ThemedText.SubHeader>
              <ThemedText.Body fontSize={24} fontWeight={500}>
                <CountUp
                  key={earnedAmount.toString()}
                  isCounting={earnedAmount?.greaterThan(JSBI.BigInt(0))}
                  decimalPlaces={4}
                  start={parseFloat(previousEarnedAmount?.toFixed(6) ?? '0')}
                  end={parseFloat(earnedAmount?.toFixed(6) ?? '0')}
                  thousandsSeparator={','}
                  duration={1}
                />
              </ThemedText.Body>
            </AutoColumn>
          </PoolData>
        </DataRow>

        {stakingInfo != null ? (
          <>
            <SuperManageModal
              isOpen={showManageModal}
              onDismiss={() => setShowManageModal(false)}
              lockIndex={selectedLock}
              stakingInfo={stakingInfo}
              userLiquidityUnstaked={userLiquidityUnstaked}
              userCrnUnstaked={userCrnUnstaked}
              unstake={true}
            />
            <SuperClaimRewardModal
              isOpen={showClaimRewardModal}
              onDismiss={() => setShowClaimRewardModal(false)}
              lockIndex={selectedLock}
              stakingInfo={stakingInfo}
            />
          </>
        ) : null}
        <LocksRow style={{ gap: '0.4rem' }}>
          {lockInfos.map((lock, i) => (
            <PoolDataBorder
              key={'lock-card-' + lock.lockIndex}
              onClick={() => setSelectedLock(lock.lockIndex)}
              style={{
                cursor: 'pointer',
                border: lock.lockIndex === selectedLock ? `1px solid ${theme.green1}` : `1px solid ${theme.bg3}`,
                padding: '0.4rem',
              }}
            >
              <AutoColumn gap="sm" justify="center">
                <ThemedText.SubHeader>{lock.duration}</ThemedText.SubHeader>
                {icons[i]}

                {!userInfos[i].crnStakedAmount.equalTo(0) && (
                  <ThemedText.Body
                    fontSize={14}
                    fontWeight={500}
                    color={userInfos[i].isUnlocked ? theme.green1 : theme.primary1}
                    alignItems="center"
                    display="flex"
                    textAlign="center"
                  >
                    {userInfos[i].isUnlocked ? (
                      <Unlocked16 style={{ marginRight: '0.5rem' }} />
                    ) : (
                      <Locked16 style={{ marginRight: '0.5rem' }} />
                    )}
                    {userInfos[i].unlockDate.toLocaleDateString()}
                  </ThemedText.Body>
                )}
              </AutoColumn>
            </PoolDataBorder>
          ))}
        </LocksRow>
        <RowBetween>
          <div>Liquidity staked in {lockInfos[selectedLock].duration} stake</div>
          <div>${lockInfos[selectedLock].totalStakedInUSD}</div>
        </RowBetween>

        {userInfos[selectedLock].stakedAmount.greaterThan('0') && (
          <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
            <BottomSection gap="lg" justify="center">
              <Wrapper disabled={disableTop}>
                <StyledCardSection style={{ position: 'relative' }}>
                  <AutoColumn gap="md" style={{ padding: '0 0 1.5rem 0' }}>
                    <ThemedText.Black fontWeight={600}>
                      <Trans>You staked</Trans>
                    </ThemedText.Black>
                    <StakeTimeRow>
                      {inStaging ? (
                        // If MasterChefV2, only show the CLP Amount (no $ amount)
                        <>
                          <AutoColumn gap="md">
                            <ThemedText.Black fontSize={24} fontWeight={600}>
                              {stakedAmount?.toSignificant(6) ?? '-'}
                            </ThemedText.Black>
                          </AutoColumn>
                          <ThemedText.Black>LP {poolHandle}</ThemedText.Black>
                        </>
                      ) : (
                        // If MasterChefV1, show $ amount as primary text and CLP amount as secondary text
                        <>
                          <AutoColumn gap="md">
                            <ThemedText.Black fontSize={24} fontWeight={600}>
                              {userInfos[selectedLock].stakedAmount.toFixed(2)} CLP +{' '}
                              {userInfos[selectedLock].crnStakedAmount.toFixed(2)} CRN
                            </ThemedText.Black>
                          </AutoColumn>
                          <AutoColumn justify="end" style={{ textAlign: 'center' }}>
                            Until{' '}
                            {userInfos[selectedLock].unlockDate.toLocaleDateString() +
                              ' - ' +
                              userInfos[selectedLock].unlockDate.toLocaleTimeString()}
                          </AutoColumn>
                        </>
                      )}
                    </StakeTimeRow>
                  </AutoColumn>
                </StyledCardSection>
              </Wrapper>
            </BottomSection>
          </PositionInfo>
        )}
      </div>
      <DataRow>
        {account ? (
          <ButtonGray
            $borderRadius="0"
            disabled={userInfos[selectedLock]?.stakedAmount?.equalTo(JSBI.BigInt(0))}
            padding="1rem"
            onClick={handleManageClick}
          >
            <ManageIcon style={{ marginRight: '0.5rem' }} />{' '}
            <Trans>Remove {lockInfos[selectedLock]?.duration} stake</Trans>
          </ButtonGray>
        ) : (
          <ButtonPrimary $borderRadius="0" disabled={false} padding="1rem" onClick={handleManageClick}>
            <Trans>Connect Wallet</Trans>
          </ButtonPrimary>
        )}

        {account && (
          <ButtonYellow
            style={{ height: '100%' }}
            disabled={earnedAmount == null || userInfos[selectedLock].earnedAmount.equalTo(JSBI.BigInt(0))}
            padding="1rem"
            $borderRadius="0"
            onClick={() => setShowClaimRewardModal(true)}
          >
            <Trans>Claim {lockInfos[selectedLock].duration} stake rewards</Trans>
          </ButtonYellow>
        )}
      </DataRow>
    </PageWrapper>
  )
}
