import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { MASTERCHEF_ADDRESS_V2 } from 'constants/addresses'
import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from 'constants/chains'
import { ZERO_ADDRESS } from 'constants/misc'
import { ATOM, CRN, FRAX, OSMO, USDC, USDT, UST, WBTC, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import JSBI from 'jsbi'
import { defaultsDeep } from 'lodash'

export type StakingCrn = StakingCrnStakedAmounts & StakingCrnFarms

export type StakingCrnStakedAmounts = {
  ID: string
  stakedAmount: CurrencyAmount<Token> | null
}
export type StakingCrnFarms = {
  ID: string
  poolId: number
  chain: SupportedChainId
  tokens: Token[]
  stakingRewardAddress: string
  lpAddress: string
  rewarderAddress: string
  isPeriodFinished: boolean
  earnedAmount: CurrencyAmount<Token>
  doubleRewardAmount: CurrencyAmount<Token>
  totalStakedAmount: CurrencyAmount<Token>
  totalStakedInUSD: number
  allocPoint: number
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: number
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: CurrencyAmount<Token>
  apr: number
  apr2: number
  doubleRewards: boolean
  inStaging: boolean
  noCrnRewards: boolean
  doubleRewardToken: Token
  isLegacy: boolean
  ratio?: number
  reserveA?: CurrencyAmount<Token>
  reserveB?: CurrencyAmount<Token>
  rewardRatePerSec?: number
}

export const dummyToken = new Token(DEFAULT_FALLBACK_CHAIN, ZERO_ADDRESS, 18, 'ZERO', 'ZERO')

export const dummyAmountCrn = CurrencyAmount.fromRawAmount(CRN[DEFAULT_FALLBACK_CHAIN], '0')
export const dummyAmount = CurrencyAmount.fromRawAmount(dummyToken, '0')

export const NULL_POOL: StakingCrn = {
  ID: 'n-0',
  poolId: 0,
  chain: 0,
  tokens: [
    new Token(DEFAULT_FALLBACK_CHAIN, ZERO_ADDRESS, 18, 'ZERO', 'ZERO'),
    new Token(DEFAULT_FALLBACK_CHAIN, ZERO_ADDRESS, 18, 'ZERO', 'ZERO'),
  ],
  stakingRewardAddress: ZERO_ADDRESS,
  lpAddress: ZERO_ADDRESS,
  rewarderAddress: '',
  isPeriodFinished: false,
  stakedAmount: dummyAmount,
  earnedAmount: dummyAmountCrn,
  doubleRewardAmount: dummyAmount,
  totalStakedAmount: dummyAmount,
  totalStakedInUSD: 0,
  allocPoint: 0,
  totalRewardRate: 1,
  rewardRate: dummyAmount,
  apr: 0,
  apr2: 0,
  doubleRewards: false,
  inStaging: false,
  noCrnRewards: false,
  doubleRewardToken: dummyToken,
  isLegacy: true,
}
const NULL_POOLS = [NULL_POOL]

/**
 * Creates a pool
 * Only set properties that are different than the `NULL_POOL`
 * @param poolData
 * @returns StakingCrn
 */
function createPool(...poolData: Partial<StakingCrn>[]): StakingCrn {
  return defaultsDeep({}, ...poolData, NULL_POOL)
}

/**
 * Creates a MasterChefV2 pool
 * Only set properties that are different than the `NULL_POOL`
 * @param poolData
 * @returns StakingCrn
 */
function createMCV2Pool(poolData: Partial<StakingCrn>): StakingCrn {
  return createPool(poolData, NULL_POOL)
}

export const rewardsPerSecond = JSBI.BigInt('1000000000')
export const totalAllocPoints = JSBI.BigInt('5')
export const tokenAmount = CurrencyAmount.fromRawAmount(dummyToken, '99')

const TESTNET_POOLS: StakingCrn[] = [
  createMCV2Pool({
    ID: 't-0',
    chain: 9000,
    poolId: 0,
    tokens: [OSMO[SupportedChainId.EVMOS_TESTNET], WBTC[SupportedChainId.EVMOS_TESTNET]],
    lpAddress: '0x8aADC289821D0Eb1f9718048763235B18c9b3DD1',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.EVMOS_TESTNET],
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 't-1',
    chain: 9000,
    poolId: 1,
    tokens: [USDC[SupportedChainId.EVMOS_TESTNET], FRAX[SupportedChainId.EVMOS_TESTNET]],
    lpAddress: '0xb2D9C26Ab3719f215a155aBF6c19A0ef0d11d078',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.EVMOS_TESTNET],
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 't-2',
    chain: 9000,
    poolId: 2,
    tokens: [USDC[SupportedChainId.EVMOS_TESTNET], WBTC[SupportedChainId.EVMOS_TESTNET]],
    lpAddress: '0x7e4563a51dc6C2E884715B81210D8f4162d6B62f',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.EVMOS_TESTNET],
    allocPoint: 75,
  }),
  createMCV2Pool({
    ID: 't-3',
    chain: 9000,
    poolId: 0,
    isLegacy: false,
    tokens: [USDC[SupportedChainId.EVMOS_TESTNET], USDT[SupportedChainId.EVMOS_TESTNET]],
    lpAddress: '0xc2Ad32A6098f16934F598A90aB8e196Bc04e0277',
    stakingRewardAddress: '0x9940A8630BeEBbF516E01A2Aa3eD1917082d9181',
    allocPoint: 100,
  }),
]

const RINKEBY_POOLS: StakingCrn[] = [
  createMCV2Pool({
    ID: 'r-0',
    chain: 4,
    poolId: 0,
    tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.RINKEBY], USDC[SupportedChainId.RINKEBY]],
    lpAddress: '0x11a158Eb16e217CD05612d09160F0aF1935Bb6dc',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.RINKEBY],
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 'r-1',
    chain: 4,
    poolId: 1,
    tokens: [WBTC[SupportedChainId.RINKEBY], UST[SupportedChainId.RINKEBY]],
    lpAddress: '0x5F7Dcde96116FE560AEA36236174b60c47d42453',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.RINKEBY],
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 'r-2',
    chain: 4,
    poolId: 2,
    tokens: [FRAX[SupportedChainId.RINKEBY], UST[SupportedChainId.RINKEBY]],
    lpAddress: '0x4ce935F93334afa717Eb293fa45a17c25A9D9768',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.RINKEBY],
    allocPoint: 50,
  }),
  createMCV2Pool({
    ID: 'r-3',
    chain: 4,
    poolId: 3,
    tokens: [OSMO[SupportedChainId.RINKEBY], ATOM[SupportedChainId.RINKEBY]],
    lpAddress: '0xB92B53a39db21761e4F314640Df88c1cB347e7b8',
    stakingRewardAddress: MASTERCHEF_ADDRESS_V2[SupportedChainId.RINKEBY],
    allocPoint: 50,
  }),
]

const MAINNET_POOLS: StakingCrn[] = [
  createMCV2Pool({
    ID: 'e-0',
    chain: 9001,
    poolId: 0,
    tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET], CRN[SupportedChainId.MAINNET]],
    lpAddress: '0x28c98da13f22fe98a93d79442b3f81c7e9c5c3c0',
    stakingRewardAddress: '0xcfe952774e02816472C25Ea0BbEfdb42DF52b671',
    isLegacy: false,
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 'e-1',
    chain: 9001,
    poolId: 1,
    tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET], USDC[SupportedChainId.MAINNET]],
    lpAddress: '0xc3edbd08ebe51cb5e824ecd1df6aafaead3bee47',
    stakingRewardAddress: '0xDF6F5bCB002d9c1516E6A197a370507B944AD1a6',
    isLegacy: false,
    allocPoint: 100,
  }),
  createMCV2Pool({
    ID: 'e-2',
    chain: 9001,
    poolId: 2,
    tokens: [USDC[SupportedChainId.MAINNET], CRN[SupportedChainId.MAINNET]],
    lpAddress: '0x80ee8297c9fcf6bbe2f4d4c9b50831cb65d61bf0',
    stakingRewardAddress: '0x43cfc66318187E93e66aAa9558F4b9318621c01e',
    isLegacy: false,
    allocPoint: 100,
  }),
]

export const STAKING: StakingCrn[] = [...MAINNET_POOLS, ...TESTNET_POOLS, ...RINKEBY_POOLS, ...NULL_POOLS]

export const ADDRESS_PRICE_MAP: { [key: string]: string } = {
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063': 'dai',
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': 'usd-coin',
  '0xD6DF932A45C0f255f85145f286eA0b292B21C90B': 'aave',
}
