import { Settings20 as ManageIcon } from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { DEFAULT_FALLBACK_CHAIN } from 'constants/chains'
import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { StakingCrn } from 'state/stake/constants'
import { SuperFarmResult, useFarmCardInfo, useSuperFarm } from 'state/stake/user-farms'
import styled from 'styled-components/macro'

import { CRN } from '../../constants/tokens'
import { useColorForToken } from '../../hooks/useColor'
import { ThemedText } from '../../theme'
import { getPairRenderOrder, isTokenAmountPositive } from '../../utils/pools'
import { ButtonYellow } from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import { AutoRow, RowBetween } from '../Row'
import ClaimRewardModal from './ClaimRewardModal'
import { Button, PairContainer, ResponsiveCurrencyLabel, StyledActionsContainer, Wrapper } from './styled'

type SimpleFarmCardProps = {
  farm: StakingCrn
}

type FarmPoolCardProps = {
  apr: number | string
  apr2: number
  doubleRewards: boolean
  inStaging: boolean
  noCrnRewards: boolean
  isLegacy?: boolean
  isPeriodFinished: boolean
  token0: Token
  token1: Token
  totalStakedInUSD: number
  doubleRewardToken: Token
  isStaking: boolean
  farmId: string
  earnedAmount: CurrencyAmount<Token>
  doubleRewardAmount: CurrencyAmount<Token>
  farm: StakingCrn | SuperFarmResult
  claimModal?: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const OuterBar = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  border-radius: 50px;
  height: 4px;
  flex: 1 1 auto;
  max-width: 200px;
  overflow: hidden;
`

const InnerBar = styled.div<{ apr: number | string | undefined; color: string | undefined }>`
  background-color: ${({ color }) => (color ? color : 'white')};
  border-radius: 50px;
  width: ${({ apr }) => (apr ? apr + '%' : '0%')};
  height: 4px;
`

const StyledAprColumn = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.bg7};
  padding: 1.25rem;
  border-radius: 0.25rem;
`

export function AprBar({
  apr,
  currency,
  color = '#ffffff',
}: {
  apr: number | string
  currency: Currency
  color?: string
}): ReactElement {
  return (
    <Container>
      <CurrencyLogo currency={currency} />
      <OuterBar>
        <InnerBar apr={apr} color={color} />
      </OuterBar>
      <ThemedText.Black>{apr}%</ThemedText.Black>
    </Container>
  )
}

const DefaultPoolCard = ({
  apr,
  apr2,
  doubleRewards,
  inStaging,
  noCrnRewards,
  isLegacy,
  isPeriodFinished,
  token0: _token0,
  token1: _token1,
  totalStakedInUSD,
  doubleRewardToken,
  isStaking,
  farmId,
  earnedAmount,
  enableClaimButton = false,
  enableModal = () => null,
}: { enableClaimButton?: boolean; enableModal?: () => void } & FarmPoolCardProps) => {
  const { currency0, currency1, token0, token1 } = getPairRenderOrder(_token0, _token1)

  const crnBackgroundColor = useColorForToken(CRN[DEFAULT_FALLBACK_CHAIN])
  const doubleRewardBackgroundColor = useColorForToken(doubleRewardToken)
  const history = useHistory()

  const totalStakedInUSDFriendly = totalStakedInUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  function renderManageOrDepositButton() {
    const sharedProps = {
      $borderRadius: '0!important',
      width: '100%',
      padding: '1rem!important',
      style: { maxWidth: 'none', height: 'fit-content', borderRadius: '0' },
      onClick: () => {
        history.push(`/farm/${farmId}`)
      },
    }

    return isStaking ? (
      <Button isStaking={true} {...sharedProps}>
        <ManageIcon style={{ marginRight: '0.5rem' }} />
        <ThemedText.Black>
          <Trans>Manage</Trans>
        </ThemedText.Black>
      </Button>
    ) : (
      <Button disabled={isPeriodFinished} isStaking={false} {...sharedProps}>
        <Trans>Withdraw</Trans>
      </Button>
    )
  }

  return (
    <Wrapper bgColor1={'#191b1f'} isDoubleRewards={doubleRewards}>
      <AutoColumn style={{ padding: '1.5rem', height: '100%', alignItems: 'start', display: 'block' }}>
        <AutoRow justifyContent="space-between">
          <PairContainer>
            <div style={{ padding: '3rem 0', marginRight: '-2rem' }}>
              <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={75} />
            </div>
            <ResponsiveCurrencyLabel>
              {currency0.symbol}-{currency1.symbol}
            </ResponsiveCurrencyLabel>
          </PairContainer>
        </AutoRow>
        <div>
          <StyledAprColumn gap="md">
            {doubleRewards && !inStaging ? (
              <>
                <ThemedText.SubHeader color={'text0'}>Rewards</ThemedText.SubHeader>
                <AprBar apr={'0'} currency={CRN[DEFAULT_FALLBACK_CHAIN]} color={crnBackgroundColor ?? undefined} />{' '}
                <AprBar apr={'0'} currency={doubleRewardToken} color={doubleRewardBackgroundColor ?? undefined} />
              </>
            ) : inStaging ? (
              `Coming Soon`
            ) : noCrnRewards ? (
              <>
                <ThemedText.SubHeader color={'text0'}>Reward</ThemedText.SubHeader>
                <AprBar apr={'0'} currency={doubleRewardToken} color={doubleRewardBackgroundColor ?? undefined} />
              </>
            ) : (
              <>
                <ThemedText.SubHeader color={'text0'}>Reward</ThemedText.SubHeader>
                <AprBar apr={'0'} currency={CRN[DEFAULT_FALLBACK_CHAIN]} color={crnBackgroundColor ?? undefined} />
              </>
            )}
          </StyledAprColumn>
          <RowBetween style={{ paddingTop: '1.5rem', alignSelf: 'end' }}>
            <AutoColumn>
              <ThemedText.SubHeader>
                <Trans>Total staked</Trans>
              </ThemedText.SubHeader>
              <ThemedText.Black fontSize="18px">{`$${totalStakedInUSDFriendly}`}</ThemedText.Black>
            </AutoColumn>
            <AutoColumn>
              <ThemedText.SubHeader textAlign="end">APR</ThemedText.SubHeader>
              <ThemedText.Black fontSize="18px" textAlign="end">
                0%
              </ThemedText.Black>
            </AutoColumn>
          </RowBetween>
          {enableClaimButton && (
            <RowBetween style={{ paddingTop: '1.5rem', alignSelf: 'end' }}>
              <AutoColumn>
                <ThemedText.SubHeader>
                  <Trans>Unclaimed rewards</Trans>
                </ThemedText.SubHeader>
                <ThemedText.Black fontSize="18px">{earnedAmount.toFixed(4)} CRN</ThemedText.Black>
              </AutoColumn>
            </RowBetween>
          )}
        </div>
      </AutoColumn>

      {isLegacy && !isStaking ? (
        <Button
          padding="1rem!important"
          $borderRadius="0!important"
          width="100%"
          style={{ maxWidth: 'none', height: 'fit-content', borderRadius: '0' }}
          disabled={true}
          isStaking={isStaking}
        >
          <Trans>Withdraw</Trans>
        </Button>
      ) : (
        <StyledActionsContainer>
          {renderManageOrDepositButton()}

          {enableClaimButton && (
            <ButtonYellow
              padding="1rem!important"
              $borderRadius="0!important"
              maxWidth="none"
              height="100%"
              onClick={enableModal}
            >
              Claim
            </ButtonYellow>
          )}
        </StyledActionsContainer>
      )}
    </Wrapper>
  )
}

const StakingPoolCard = (props: FarmPoolCardProps) => {
  const { farmId, earnedAmount, doubleRewardAmount, farm } = props

  const amountIsClaimable = isTokenAmountPositive(earnedAmount) || isTokenAmountPositive(doubleRewardAmount)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const enableModal = () => setShowClaimRewardModal(true)
  return (
    <>
      {showClaimRewardModal && farm && (
        <ClaimRewardModal
          isOpen={showClaimRewardModal}
          onDismiss={() => setShowClaimRewardModal(false)}
          stakingInfo={farm}
        />
      )}
      <DefaultPoolCard {...props} enableClaimButton={props.claimModal} enableModal={enableModal} />
    </>
  )
}

const FarmPoolCard = (props: SimpleFarmCardProps) => {
  const { isLegacy } = props.farm
  if (isLegacy) {
    return <LegacyFarmPoolCard {...props} />
  }

  return <SuperFarmPoolCard {...props}></SuperFarmPoolCard>
}

const LegacyFarmPoolCard = (props: SimpleFarmCardProps) => {
  const { ID, tokens, ratio, reserveA, reserveB, rewardRatePerSec } = props.farm
  const isStaking = isTokenAmountPositive(props.farm.stakedAmount)
  const { apr, apr2, totalStakedInUSD } = useFarmCardInfo(
    ratio ?? 0,
    reserveA,
    reserveB,
    rewardRatePerSec ?? 0,
    tokens[0],
    tokens[1]
  )

  if (isStaking) {
    return (
      <StakingPoolCard
        {...props.farm}
        farmId={ID}
        token0={tokens[0]}
        token1={tokens[1]}
        isStaking={isStaking}
        farm={props.farm}
        apr={apr}
        apr2={apr2}
        totalStakedInUSD={totalStakedInUSD}
        claimModal={true}
      />
    )
  }

  return (
    <DefaultPoolCard
      {...props.farm}
      farmId={ID}
      token0={tokens[0]}
      token1={tokens[1]}
      isStaking={isStaking}
      farm={props.farm}
      apr={apr}
      apr2={apr2}
      totalStakedInUSD={totalStakedInUSD}
    ></DefaultPoolCard>
  )
}

const SuperFarmPoolCard = (props: SimpleFarmCardProps) => {
  const { ID } = props.farm
  const farm = useSuperFarm(ID)
  const isStaking = isTokenAmountPositive(farm.stakedAmount)

  if (isStaking) {
    return (
      <StakingPoolCard
        {...farm}
        farmId={ID}
        token0={farm.tokens[0]}
        token1={farm.tokens[1]}
        isStaking={isStaking}
        farm={farm}
        apr={farm.apr}
        apr2={farm.apr2}
        totalStakedInUSD={farm.totalStakedInUSD}
        claimModal={false}
      />
    )
  }

  return (
    <DefaultPoolCard
      {...farm}
      farmId={ID}
      token0={farm.tokens[0]}
      token1={farm.tokens[1]}
      isStaking={isStaking}
      farm={farm}
      apr={farm.apr}
      apr2={farm.apr2}
      totalStakedInUSD={farm.totalStakedInUSD}
    ></DefaultPoolCard>
  )
}

export default FarmPoolCard
