import { Menu16 } from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
import { RowBetween } from 'components/Row'
import { ReactNode, useRef } from 'react'
import { X } from 'react-feather'
import { Text } from 'rebass'
import styled from 'styled-components/macro'

import { useModalClose, useModalOpen, useToggleMobileMenu } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { AutoColumn } from '../Column'
import Modal from '../Modal'

const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 40px;
  color: ${({ theme }) => theme.text2};
  padding: 0.15rem 0.5rem;
  border-radius: 16px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: ${({ theme }) => theme.text1};
  }

  svg {
    margin-top: 2px;
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;
  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

/*
const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  user-select: none;
`
*/

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

const ModalContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function MenuModal(props: { children: ReactNode }) {
  // const { chainId } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MOBILEMENU)
  const toggle = useToggleMobileMenu()
  const close = useModalClose()
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton
        onClick={toggle}
        style={{ width: '100%', justifyContent: 'center' }}
        id="open-mobile-menu-button"
      >
        <Menu16 style={{ marginRight: '0.5rem' }} />
        <Text>Menu</Text>
      </StyledMenuButton>
      <Modal isOpen={open} onDismiss={close} toggleOnPageChange>
        <ModalContentWrapper style={{ zIndex: 100 }} onClick={(e) => e.preventDefault()}>
          <AutoColumn gap="md" style={{ padding: '1rem 0 2rem 0', width: '100%' }}>
            <RowBetween style={{ padding: '1rem 1rem' }}>
              <Text fontWeight={500} fontSize={20}>
                <Trans>Menu</Trans>
              </Text>
              <StyledCloseIcon onClick={toggle} />
            </RowBetween>
            <Break />
            {props.children}
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
    </StyledMenu>
  )
}
