import { darken } from 'polished'
import styled from 'styled-components/macro'

import uImage from '../../assets/images/big_unicorn.png'
import noise from '../../assets/images/noise.png'
import xlUnicorn from '../../assets/images/xl_uni.png'
import { ThemedText } from '../../theme'
import { ButtonPrimary } from '../Button'
import Card from '../Card'
import { AutoColumn } from '../Column'

export const DataCard = styled(AutoColumn)<{ disabled?: boolean }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #ff007a 0%, #2172e5 100%);
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow: hidden;
`

export const CardBGImage = styled.span<{ desaturate?: boolean }>`
  background: url(${uImage});
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 12px;
  opacity: 0.4;
  top: -100px;
  left: -100px;
  transform: rotate(-15deg);
  user-select: none;
  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
  background: url(${xlUnicorn});
  width: 1200px;
  height: 1200px;
  position: absolute;
  border-radius: 12px;
  top: -300px;
  left: -300px;
  opacity: 0.4;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardNoise = styled.span`
  background: url(${noise});
  background-size: cover;
  mix-blend-mode: overlay;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
`

export const Break = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
`

export const Wrapper = styled(Card)<{ bgColor1: string | null; bgColor2?: string | null; isDoubleRewards: boolean }>`
  border-radius: 0;
  background: ${({ theme }) => theme.bg0} !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
  box-shadow: ${({ isDoubleRewards, theme }) =>
    isDoubleRewards
      ? `0px 0px 8px 5px rgba(255,255,255,0.05)`
      : `0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`};
  position: relative;
  padding: 0;
  min-height: 500px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      grid-template-rows: auto 1fr;
`};
`

export const PairContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ResponsiveCurrencyLabel = styled(ThemedText.Black)`
  font-size: 28px !important;
  padding-bottom: 1.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size: 14 !important;
  `};
`

export const Button = styled(ButtonPrimary)<{ isStaking: boolean }>`
  background: ${({ isStaking, theme }) => (isStaking ? theme.bg1 : theme.primary1)};
  font-size: 18px;
  font-weight: 500;

  ${({ isStaking, theme }) =>
    isStaking &&
    `
        min-width: fit-content;
        max-width: fit-content;
        &:focus, &:hover, &:active {
          background-color: ${darken(0.1, theme.bg2)};
        }
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 4px;
        border-radius: 5px;
    `};
`

export const StyledActionsContainer = styled.div`
  display: flex;
`

export const TokenPairBackgroundColor = styled.span<{ bgColor1: string | null; bgColor2?: string | null }>`
  background: ${({ theme, bgColor1, bgColor2 }) => '#191b1f'};
  background-size: cover;
  mix-blend-mode: overlay;
  border-radius: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`
