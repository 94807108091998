import { BigNumber } from '@ethersproject/bignumber'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { MASTERCHEF_ADDRESS_V2 } from 'constants/addresses'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSingleCallResult } from 'lib/hooks/multicall'

import { DEFAULT_FALLBACK_CHAIN } from '../constants/chains'
import { useTokenContract } from '../hooks/useContract'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalStakedInPool(token?: Token): CurrencyAmount<Token> | undefined {
  const { chainId } = useActiveWeb3React()
  const masterChefAddress = chainId ? MASTERCHEF_ADDRESS_V2[chainId] : MASTERCHEF_ADDRESS_V2[DEFAULT_FALLBACK_CHAIN]

  const contract = useTokenContract(token?.address, false)
  const totalStakedInPool: BigNumber = useSingleCallResult(contract, 'balanceOf', [masterChefAddress])?.result?.[0]

  return token && totalStakedInPool ? CurrencyAmount.fromRawAmount(token, totalStakedInPool.toString()) : undefined
}

export function useTotalStakedInSuperFarm(
  address?: string | undefined,
  token?: Token
): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.address, false)
  const totalStakedInPool: BigNumber = useSingleCallResult(contract, 'balanceOf', [address])?.result?.[0]

  return token && totalStakedInPool ? CurrencyAmount.fromRawAmount(token, totalStakedInPool.toString()) : undefined
}
