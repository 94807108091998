import {
  Collaborate16,
  Connect16,
  /*DataSet16,*/
  Wheat16,
} from '@carbon/icons-react'
import { Trans } from '@lingui/macro'
// import useScrollPosition from '@react-hook/window-scroll'
import { CHAIN_INFO } from 'constants/chainInfo'
import { DEFAULT_FALLBACK_CHAIN } from 'constants/chains'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { FileText, GitHub, MessageCircle, Moon, Sun, Trello, Twitter } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useAppSelector } from 'state/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useNativeCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'

import { ReactComponent as LogoSmall } from '../../assets/svg/logo-small.svg'
import { ReactComponent as Logo } from '../../assets/svg/logo-text.svg'
import { ExternalLink } from '../../theme'
import {
  // ExternalLink,
  ThemedText,
} from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import { CardNoise } from '../earn/styled'
import Row from '../Row'
import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import MenuModal from './MenuModal'
// import NetworkSelector from './NetworkSelector'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
`

const HeaderInner = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 0;
  z-index: 21;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  grid-template-columns: 48px 1fr;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 4.5rem;
  padding:  0 1rem;
  grid-template-columns: 1fr 1fr;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding:  0 1rem;
  grid-template-columns: 36px 1fr;
`};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`
const HeaderElementAtLarge = styled(HeaderElement)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  width: fit-content;
  padding: 0;
  height: 4.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 0.25rem;
    height: 3rem;
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    padding: 0 1rem;
    width: 480px;
    display: flex;
    background-color: transparent;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100vw;
  `}
`

const Separator = styled.div`
  height: 3rem;
  width: 1px;
  background-color: ${({ theme }) => theme.bg2};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg0 : theme.bg0)};
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const CRNWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: start;
  `};
  :hover {
    cursor: pointer;
  }
`

const CronusIconSmall = styled.div`
  display: flex;
  margin-right: 1rem;
  position: relative;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  height: 40px;
`};
`

const CronusIcon = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  display:none;
`};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  height: 100%;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  &.${activeClassName} {
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    justify-content: start;
    &:active,   &.${activeClassName} {
      justify-content: start;
    }
  `}
`

const DisabledNavLink = styled(StyledNavLink)`
  color: ${({ theme }) => theme.text4};
  pointer-events: none;
  text-decoration: none;
`
const StyledNavLinkAtLarge = styled(StyledNavLink)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none
  `}
`

const HeaderElementBelowLarge = styled(HeaderElement)`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg2};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    justify-content: start;
  `}
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    justify-content: start;
  `}
`

const InternalMenuItem = styled(NavLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    justify-content: start;
  `}
`
const MarginIcon = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0.75rem
  `}
`

const LinkIcon = styled.div`
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.bg3};
`

function NavItems({ chainId }: { chainId: number | undefined }) {
  const isUsCitizen = useAppSelector((state) => state.user.isUsCitizen)

  return (
    <StyledNavItems>
      <StyledNavLink
        id={`pool-nav-link`}
        to={'/'}
        isActive={(match, { pathname }) =>
          pathname === '/' ||
          pathname.startsWith('/add') ||
          pathname.startsWith('/remove') ||
          pathname.startsWith('/increase') ||
          pathname.startsWith('/find')
        }
      >
        <LinkIcon>
          <Collaborate16 />
        </LinkIcon>
        <Trans>Liquidity</Trans>
      </StyledNavLink>
      <StyledNavLink
        id={`swap-nav-link`}
        to={'/farms'}
        isActive={(match, { pathname }) => Boolean(match) || pathname.startsWith('/farm')}
      >
        <LinkIcon>
          <Wheat16 />
        </LinkIcon>
        <Trans>Farms</Trans>
      </StyledNavLink>
    </StyledNavItems>
  )
}

const StyledNavItems = styled.div`
  display: flex;
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    row-gap: 12px;
  `}
`

const StyledNavItemsAtLarge = styled.div`
  height: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
function MenuItems() {
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  return (
    <>
      <InternalMenuItem to="/bridge">
        <div>
          <Trans>Bridge</Trans>
        </div>
        <MarginIcon>
          <Connect16 style={{ opacity: 0.6 }} />
        </MarginIcon>
      </InternalMenuItem>
      <MenuItem href="https://medium.com/@cronusfinance" target="about:blank">
        <span>
          <Trans>Medium</Trans>
        </span>
        <MarginIcon>
          <Trello opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://twitter.com/cronusfinance" target="about:blank">
        <span>
          <Trans>Twitter</Trans>
        </span>
        <MarginIcon>
          <Twitter opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://discord.gg/cronusfinance" target="about:blank">
        <span>
          <Trans>Discord</Trans>
        </span>
        <MarginIcon>
          <MessageCircle opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://github.com/cronus-finance" target="about:blank">
        <span>
          <Trans>Github</Trans>
        </span>
        <MarginIcon>
          <GitHub opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <MenuItem href="https://cronus-finance.gitbook.io" target="about:blank">
        <span>
          <Trans>Docs</Trans>
        </span>
        <MarginIcon>
          <FileText opacity={0.6} size={16} />
        </MarginIcon>
      </MenuItem>
      <ToggleMenuItem onClick={() => toggleDarkMode()}>
        <span>{darkMode ? <Trans>Light Theme</Trans> : <Trans>Dark Theme</Trans>}</span>
        <MarginIcon>{darkMode ? <Sun opacity={0.6} size={16} /> : <Moon opacity={0.6} size={16} />}</MarginIcon>
      </ToggleMenuItem>
    </>
  )
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { white, black } = useTheme()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const showClaimPopup = useShowClaimPopup()

  //  const scrollY = useScrollPosition()

  const {
    // infoLink,
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : DEFAULT_FALLBACK_CHAIN]
  return (
    <HeaderFrame showBackground={true}>
      <HeaderInner>
        <ClaimModal />
        <Title href=".">
          <CronusIconSmall>
            <LogoSmall fill={darkMode ? white : '#B50028'} height="36px" title="logo" />
          </CronusIconSmall>
          <CronusIcon>
            <Logo fill={darkMode ? white : black} height="36px" title="logo" />
          </CronusIcon>
        </Title>
        <HeaderControls>
          <HeaderLinks>
            <HeaderElementBelowLarge>
              <MenuModal>
                <NavItems chainId={chainId} />
                <Break />
              </MenuModal>
            </HeaderElementBelowLarge>
            <StyledNavItemsAtLarge>
              <NavItems chainId={chainId} />
            </StyledNavItemsAtLarge>
          </HeaderLinks>

          <Separator />
          <HeaderElement>
            {false && availableClaim && !showClaimPopup && (
              <CRNWrapper onClick={toggleClaimModal}>
                <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  <ThemedText.White padding="0 2px">
                    {claimTxn && !claimTxn?.receipt ? (
                      <Dots>
                        <Trans>Claiming CRN</Trans>
                      </Dots>
                    ) : (
                      <Trans>Claim CRN</Trans>
                    )}
                  </ThemedText.White>
                </UNIAmount>
                <CardNoise />
              </CRNWrapper>
            )}
            <AccountElement active={!!account}>
              {account ? (
                <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.5rem" pr="0.5rem" fontWeight={500}>
                  <Trans>
                    {userEthBalance?.toSignificant(3) ?? 0} {nativeCurrencySymbol}
                  </Trans>
                </BalanceText>
              ) : null}
              <Separator />
              <Web3Status />
            </AccountElement>
          </HeaderElement>
        </HeaderControls>
      </HeaderInner>
    </HeaderFrame>
  )
}
