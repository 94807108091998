import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { ButtonError, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import { ConfirmTetraAddModalBottom } from 'components/TetraPool/ConfirmTetraAddModalBottom'
import FixedCurrencyInputPanel from 'components/TetraPool/FixedCurrencyInputPanel'
import TetraCurrencyLogo from 'components/TetraPool/TetraLogo'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { POOLS_MAP } from 'constants/tetrapools'
import { BigNumber } from 'ethers/lib/ethers'
import { parseUnits } from 'ethers/lib/utils'
import { TransactionResponse } from 'ethers/node_modules/@ethersproject/providers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import useTetraPoolData from 'hooks/useTetraPoolData'
import { useTokenFormState } from 'hooks/useTokenFormState'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import styled from 'lib/theme'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
// import { PoolPriceBar } from 'pages/AddLiquidityV2/PoolPriceBar'
import { Dots, Wrapper } from 'pages/Pool/styleds'
import { ReactElement, useCallback, useContext, useState } from 'react'
import { Plus } from 'react-feather'
import { Text } from 'rebass'
import { useWalletModalToggle } from 'state/application/hooks'
import { useTetraContract } from 'state/tetraswap/hooks'
import { TransactionType } from 'state/transactions/actions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useIsExpertMode, useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import { ThemeContext } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { maxAmountSpend } from 'utils/maxAmountSpend'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

const LightCardNoBorder = styled(LightCard)`
  border: 0 solid transparent;
`

export default function Deposit({ poolId }: { poolId: string }): ReactElement | null {
  const POOL = POOLS_MAP[poolId]

  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()

  const expertMode = useIsExpertMode()
  const theme = useContext(ThemeContext)
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users

  const tokens = POOL.poolTokens
  const [tokenFormState, updateTokenFormState] = useTokenFormState(tokens)

  const swapContract = useTetraContract(poolId)

  //const [poolData, userShareData, currencyBalances, error] = useTetraPoolData(poolId)

  const { minToMint, poolData, userData } = useTetraPoolData(poolId, tokenFormState)
  // mock data to test initial interface

  const error = false

  // end mock data

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  const [txHash, setTxHash] = useState<string>('')

  const [approvalA, approveACallback] = useApproveCallback(
    tryParseCurrencyAmount(tokenFormState[tokens[0].address], tokens[0]) ?? undefined,
    swapContract?.address
  )
  const [approvalB, approveBCallback] = useApproveCallback(
    tryParseCurrencyAmount(tokenFormState[tokens[1].address], tokens[1]) ?? undefined,
    swapContract?.address
  )
  const [approvalC, approveCCallback] = useApproveCallback(
    tryParseCurrencyAmount(tokenFormState[tokens[2].address], tokens[2]) ?? undefined,
    swapContract?.address
  )
  const [approvalD, approveDCallback] = useApproveCallback(
    tryParseCurrencyAmount(tokenFormState[tokens[3].address], tokens[3]) ?? undefined,
    swapContract?.address
  )

  async function onConfirmTransaction() {
    setAttemptingTxn(true)
    const txnAmounts: BigNumber[] = tokens.map((token) =>
      parseUnits(tokenFormState[token.address] ? tokenFormState[token.address] : '0')
    )
    if (swapContract && txnAmounts && deadline) {
      await swapContract
        .addLiquidity(txnAmounts, parseUnits(minToMint.toExact()), deadline)
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)
          addTransaction(response, {
            type: TransactionType.ADD_LIQUIDITY_TETRA_POOL,
            poolName: POOL.poolName ?? undefined,
            lpTokensReceived: '0',
          })
          setTxHash(response.hash)
          // Clear input after deposit
          updateTokenFormState(
            tokens.reduce(
              (acc, t) => ({
                ...acc,
                [t.address]: '',
              }),
              {}
            )
          )
        })
        .catch((e: any) => {
          console.log(e)
          setAttemptingTxn(false)
          // Clear input after deposit
          updateTokenFormState(
            tokens.reduce(
              (acc, t) => ({
                ...acc,
                [t.address]: '',
              }),
              {}
            )
          )
        })
    } else {
      setAttemptingTxn(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      updateTokenFormState(
        tokens.reduce(
          (acc, t) => ({
            ...acc,
            [t.address]: '',
          }),
          {}
        )
      )
    }
    setTxHash('')
  }, [tokens, txHash, updateTokenFormState])

  function updateTokenFormValue(address: string, value: string): void {
    const initialNewState = { [address]: value }
    const addProportional = false
    if (addProportional) {
      tokens.reduce((a, t) => {
        if (t.address === address) {
          return {
            ...a,
            [t.address]: value,
          }
        }
        const derivedValue = 0
        return {
          ...a,
          [t.address]: derivedValue,
        }
      }, {})
    }
    updateTokenFormState(initialNewState)
  }

  const maxAmounts: { [k: string]: CurrencyAmount<Token> } = tokens.reduce((accumulator, token) => {
    return {
      ...accumulator,
      [token.address]: maxAmountSpend(userData.currencyBalances[token.address]),
    }
  }, {})

  const pendingText = <Trans>Supplying liquidity to TetraPool</Trans>

  const modalHeader = () => {
    return (
      <AutoColumn style={{ padding: '0 1rem' }} gap="20px">
        <RowBetween alignItems="center" style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {minToMint?.toFixed(4)}
          </Text>
          <TetraCurrencyLogo
            currency0={tokens[0]}
            currency1={tokens[1]}
            currency2={tokens[2]}
            currency3={tokens[3]}
            size={30}
          />
        </RowBetween>
        <Row>
          <Text fontSize="24px">Tetrapool tokens</Text>
        </Row>
        <ThemedText.Italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </ThemedText.Italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmTetraAddModalBottom
        currencies={tokens}
        parsedAmounts={tokenFormState}
        onAdd={onConfirmTransaction}
        poolTokenPercentage={poolData.tokenPercentage}
      />
    )
  }

  return (
    <>
      <Wrapper>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>You will receive</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={modalHeader}
              bottomContent={modalBottom}
            />
          )}
          pendingText={pendingText}
          currencyToAdd={POOL.lpToken}
        />
        <AutoColumn gap="10px">
          {tokens.map((token, index) => (
            <div key={`${token.symbol}-input-panel`}>
              <FixedCurrencyInputPanel
                value={tokenFormState[token.address]}
                onUserInput={(value) => {
                  updateTokenFormValue(token.address, value)
                }}
                currency={token}
                id={`tetraswap-currency-${index}-input`}
                onMax={() => {
                  updateTokenFormValue(token.address, maxAmounts[token.address].toExact())
                }}
                disableMaxButton={tokenFormState[token.address] === maxAmounts[token.address].toExact()}
                renderBalance
              />
              {index !== tokens.length - 1 && (
                <ColumnCenter>
                  <Plus size="16" color={theme.text2} />
                </ColumnCenter>
              )}
            </div>
          ))}
          {false && (
            <>
              <LightCard padding="0px" $borderRadius={'0'}>
                <RowBetween padding="1rem">
                  <ThemedText.SubHeader fontWeight={500} fontSize={14}>
                    <Trans>Prices and pool share</Trans>
                  </ThemedText.SubHeader>
                </RowBetween>{' '}
                <LightCardNoBorder padding="1rem" $borderRadius={'0'}>
                  {/* <PoolPriceBar
                    currencies={currencies}
                    poolTokenPercentage={poolTokenPercentage}
                    noLiquidity={noLiquidity}
                    price={price}
                  /> */}
                </LightCardNoBorder>
              </LightCard>
            </>
          )}
        </AutoColumn>
      </Wrapper>
      {!account ? (
        <ButtonPrimary onClick={toggleWalletModal}>
          <Trans>Connect Wallet</Trans>
        </ButtonPrimary>
      ) : (
        <RowBetween>
          {(approvalA === ApprovalState.NOT_APPROVED ||
            approvalA === ApprovalState.PENDING ||
            approvalB === ApprovalState.NOT_APPROVED ||
            approvalB === ApprovalState.PENDING ||
            approvalC === ApprovalState.NOT_APPROVED ||
            approvalC === ApprovalState.PENDING ||
            approvalD === ApprovalState.NOT_APPROVED ||
            approvalD === ApprovalState.PENDING) &&
            isValid && (
              <>
                {approvalA !== ApprovalState.APPROVED && (
                  <ButtonPrimary onClick={approveACallback} disabled={approvalA === ApprovalState.PENDING}>
                    {approvalA === ApprovalState.PENDING ? (
                      <Dots>
                        <Text fontSize={20} fontWeight={500}>
                          <Trans>Approving</Trans>
                        </Text>
                      </Dots>
                    ) : (
                      <Text fontSize={20} fontWeight={500}>
                        <Trans>Approve {tokens[0].symbol}</Trans>
                      </Text>
                    )}
                  </ButtonPrimary>
                )}
                {approvalB !== ApprovalState.APPROVED &&
                  approvalA !== ApprovalState.NOT_APPROVED &&
                  approvalA !== ApprovalState.PENDING && (
                    <ButtonPrimary onClick={approveBCallback} disabled={approvalB === ApprovalState.PENDING}>
                      {approvalB === ApprovalState.PENDING ? (
                        <Dots>
                          <Text fontSize={20} fontWeight={500}>
                            <Trans>Approving</Trans>
                          </Text>
                        </Dots>
                      ) : (
                        <Text fontSize={20} fontWeight={500}>
                          <Trans>Approve {tokens[1].symbol}</Trans>
                        </Text>
                      )}
                    </ButtonPrimary>
                  )}
                {approvalC !== ApprovalState.APPROVED &&
                  approvalC !== ApprovalState.UNKNOWN &&
                  approvalA !== ApprovalState.NOT_APPROVED &&
                  approvalA !== ApprovalState.PENDING &&
                  approvalB !== ApprovalState.NOT_APPROVED &&
                  approvalB !== ApprovalState.PENDING && (
                    <ButtonPrimary onClick={approveCCallback} disabled={approvalC === ApprovalState.PENDING}>
                      {approvalC === ApprovalState.PENDING ? (
                        <Dots>
                          <Text fontSize={20} fontWeight={500}>
                            <Trans>Approving</Trans>
                          </Text>
                        </Dots>
                      ) : (
                        <Text fontSize={20} fontWeight={500}>
                          <Trans>Approve {tokens[2].symbol}</Trans>
                        </Text>
                      )}
                    </ButtonPrimary>
                  )}
                {approvalD !== ApprovalState.APPROVED &&
                  approvalD !== ApprovalState.UNKNOWN &&
                  approvalA !== ApprovalState.NOT_APPROVED &&
                  approvalA !== ApprovalState.PENDING &&
                  approvalB !== ApprovalState.NOT_APPROVED &&
                  approvalB !== ApprovalState.PENDING &&
                  approvalC !== ApprovalState.NOT_APPROVED &&
                  approvalC !== ApprovalState.PENDING && (
                    <ButtonPrimary onClick={approveDCallback} disabled={approvalD === ApprovalState.PENDING}>
                      {approvalD === ApprovalState.PENDING ? (
                        <Dots>
                          <Text fontSize={20} fontWeight={500}>
                            <Trans>Approving</Trans>
                          </Text>
                        </Dots>
                      ) : (
                        <Text fontSize={20} fontWeight={500}>
                          <Trans>Approve {tokens[3].symbol}</Trans>
                        </Text>
                      )}
                    </ButtonPrimary>
                  )}
              </>
            )}
          {((approvalA !== ApprovalState.NOT_APPROVED &&
            approvalA !== ApprovalState.PENDING &&
            approvalB !== ApprovalState.NOT_APPROVED &&
            approvalB !== ApprovalState.PENDING &&
            approvalC !== ApprovalState.NOT_APPROVED &&
            approvalC !== ApprovalState.PENDING &&
            approvalD !== ApprovalState.NOT_APPROVED &&
            approvalD !== ApprovalState.PENDING) ||
            !isValid) && (
            <ButtonError
              onClick={() => {
                expertMode ? onConfirmTransaction() : setShowConfirm(true)
              }}
              disabled={
                !isValid ||
                (approvalA !== ApprovalState.APPROVED && !!tokenFormState[tokens[0].address]) ||
                (approvalB !== ApprovalState.APPROVED && !!tokenFormState[tokens[1].address]) ||
                (approvalC !== ApprovalState.APPROVED && !!tokenFormState[tokens[2].address]) ||
                (approvalD !== ApprovalState.APPROVED && !!tokenFormState[tokens[3].address]) ||
                Object.values(tokenFormState).every((value) => +value === 0)
              }
              error={!isValid && Object.values(tokenFormState).every((value) => +value <= 0)}
            >
              <Text fontSize={20} fontWeight={500}>
                {error ? error : <Trans>Supply</Trans>}
              </Text>
            </ButtonError>
          )}
        </RowBetween>
      )}
    </>
  )
}
