import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { CurrencyAmount } from '@uniswap/sdk-core'
import DOUBLE_STAKING_ABI from 'abis/DoubleStaking.json'
import MASTERCHEF_V2_ABI from 'abis/masterchefv2.json'
import STABLE_CRONUS_STAKING_ABI from 'abis/stable-cronus-staking.json'
import { MASTERCHEF_ADDRESS_V2, STABLE_CRONUS_STAKING_ADDRESS } from 'constants/addresses'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { Dispatch, useEffect, useMemo, useState } from 'react'

export type AddressMap = { [chainId: number]: string }

export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  const getProviderOrSigner = account ? getSigner(library, account) : library
  return getProviderOrSigner
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      const contract = getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
      return contract
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useMasterChefV2Contract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MASTERCHEF_ADDRESS_V2[chainId] : undefined, MASTERCHEF_V2_ABI, withSignerIfPossible)
}

export function useSuperFarmingContract(address: any, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, DOUBLE_STAKING_ABI, withSignerIfPossible)
}

export function useUserInfo(farm: any, token: any) {
  const { account } = useActiveWeb3React()

  const contract = useMasterChefV2Contract()

  const args = useMemo(() => {
    if (!account) {
      return
    }
    return [String(farm.id), String(account)]
  }, [farm, account])

  const result = useSingleCallResult(args ? contract : null, 'userInfo', args)?.result

  const value = result?.[0]

  const amount = value ? JSBI.BigInt(value.toString()) : undefined

  return amount ? CurrencyAmount.fromRawAmount(token, amount) : undefined
}

/*
    Currently expensive to render farm list item. The infinite scroll is used to
    to minimize this impact. This hook pairs with it, keeping track of visible
    items and passes this to <InfiniteScroll> component.
  */
export function useInfiniteScroll(items: any[]): [number, Dispatch<number>] {
  const [itemsDisplayed, setItemsDisplayed] = useState(10)
  useEffect(() => setItemsDisplayed(10), [items.length])
  return [itemsDisplayed, setItemsDisplayed]
}

export function getMultipleContracts(
  addressList: string[],
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract[] {
  return addressList.map((address) => getContract(address, ABI, library, account))
}

export function useMultipleContracts(
  addressList: string[] | undefined,
  ABI: any,
  withSignerIfPossible = true
): Contract[] | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressList || !addressList.length || !ABI || !library) return null
    try {
      return getMultipleContracts(addressList, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressList, ABI, library, withSignerIfPossible, account])
}

export function useStableCronusStakingContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId ? STABLE_CRONUS_STAKING_ADDRESS[chainId] : undefined,
    STABLE_CRONUS_STAKING_ABI,
    withSignerIfPossible
  )
}
