/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
// import { TokenAmount } from '@uniswap/sdk-core'
import { Interface } from '@ethersproject/abi'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import ERC20ABI from 'abis/erc20.json'
import { MASTERCHEF_ADDRESS_V2 } from 'constants/addresses'
import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from 'constants/chains'
import { useTotalStakedInPool, useTotalStakedInSuperFarm } from 'data/TotalStakedInPool'
import { parseUnits } from 'ethers/lib/utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTotalSupply } from 'hooks/useTotalSupply'
import useUSDCPrice from 'hooks/useUSDCPrice'
// import { useTokensBalanceInPool } from 'hooks/useTokensBalanceInPool'
import JSBI from 'jsbi'
import { useMultipleContractSingleData, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useMemo } from 'react'
import { getAPR } from 'utils/getApr'

import {
  CRN,
  USDC,
  //  DAI, TST, USDC, WRAPPED_NATIVE_CURRENCY
} from '../../constants/tokens'
import { PairState, usePair, usePairs } from '../../data/Reserves'
// import { useFetchStakingInfoData } from '../../fetchers/farms'
import { NULL_POOL, STAKING, StakingCrn, tokenAmount } from './constants'
import { useMasterChefV2Contract, useSuperFarmingContract } from './hooks-sushi'

// gets the staking info from the network for the active chain id
export function useSingleFarm(farmId: string): StakingCrn {
  const { chainId, account } = useActiveWeb3React()
  const farm = STAKING.find((farm) => farm.ID === farmId)
  const poolId = farm?.poolId ?? undefined
  // const stakingInfoData = useFetchStakingInfoData() // @TODO: Restore when we have a fetcher
  const args = [poolId?.toString(), account?.toString()]
  const contract = useMasterChefV2Contract()

  const pendingTokens = useSingleCallResult(contract, 'pendingTokens', args) // user related
  const userInfo = useSingleCallResult(contract, 'userInfo', args) // user related
  const poolInfo = useSingleCallResult(contract, 'poolInfo', [poolId?.toString()])
  const totalAllocPoint = useSingleCallResult(contract, 'totalAllocPoint')
  const cronusPerSec = useSingleCallResult(contract, 'cronusPerSec')

  const pendingCrn = pendingTokens ? pendingTokens.result?.['pendingCronus'] : undefined
  const pendingComplexRewards = pendingTokens ? pendingTokens.result?.['pendingBonusToken'] : undefined
  // get all the info from the staking rewards contracts
  const tokens = farm?.tokens
  const doubleRewardToken = farm?.doubleRewardToken
  const [tokenA, tokenB] = tokens ?? []
  const [pairState, pair] = usePair(tokenA, tokenB)
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const lpInFarm = useTotalStakedInPool(pair?.liquidityToken)
  const tokenAPrice = useUSDCPrice(tokenA)
  const tokenBPrice = useUSDCPrice(tokenB)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const crnPrice = useUSDCPrice(CRN[farm!.chain])

  const result = useMemo(() => {
    // Loading
    if (userInfo?.loading || pendingTokens?.loading || pairState === PairState.LOADING || !totalSupply) {
      return farm!
    }

    // Error
    if (
      userInfo.error ||
      poolInfo.error ||
      pendingTokens.error ||
      pair == null ||
      // stakingInfoData?.[farmId] == null ||
      tokenA == null ||
      tokenB == null ||
      !totalSupply ||
      !lpInFarm
    ) {
      console.error('Failed to load staking rewards info')
      return farm!
    }
    const userInfoPool = JSBI.BigInt(userInfo.result?.['amount'] ?? 0)
    const earnedRewardPool = JSBI.BigInt(pendingCrn ?? 0)
    const earnedComplexRewardPool = JSBI.BigInt(pendingComplexRewards ?? 0)

    const stakedAmount = CurrencyAmount.fromRawAmount(pair.liquidityToken, JSBI.BigInt(0))
    const earnedAmount = CurrencyAmount.fromRawAmount(CRN[DEFAULT_FALLBACK_CHAIN], JSBI.BigInt(earnedRewardPool))
    const earnedComplexAmount = CurrencyAmount.fromRawAmount(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      farm!.doubleRewardToken,
      JSBI.BigInt(earnedComplexRewardPool)
    )

    // const { totalStakedInUSD, totalRewardRate, apr, apr2 } = stakingInfoData[farmId] // @TODO: Restore when we have a fetcher
    const reserveA = tokenA ? pair?.reserveOf(tokenA) : undefined
    const reserveB = tokenB ? pair?.reserveOf(tokenB) : undefined
    // const tokenBperLP = lpInFarm ? Number(reserveB?.toFixed()) / Number(lpInFarm.toFixed()) : undefined
    // const tokenAperLP = lpInFarm ? Number(reserveA?.toFixed()) / Number(lpInFarm.toFixed()) : undefined
    const totalStakedInUSD =
      ((Number(reserveA?.toFixed()) * Number(tokenAPrice?.toFixed() ?? 0) +
        Number(reserveB?.toFixed()) * Number(tokenBPrice?.toFixed() ?? 0)) *
        Number(lpInFarm.toFixed(6))) /
      Number(totalSupply.toFixed())

    const allocPoint = JSBI.BigInt(poolInfo.result?.['allocPoint'] ?? 0)
    const totalAllocPointPool = JSBI.BigInt(totalAllocPoint.result?.[0] ?? 0)
    const cronusPerSecPool = JSBI.BigInt(cronusPerSec.result?.[0] ?? 0)

    const rewardRatePerSec =
      JSBI.toNumber(cronusPerSecPool) * (JSBI.toNumber(allocPoint) / (JSBI.toNumber(totalAllocPointPool) * 10 ** 18))
    const totalRewardRate = 3600 * 24 * 7 * rewardRatePerSec

    const apr = getAPR(crnPrice, rewardRatePerSec, totalStakedInUSD)
    return {
      ...farm!,
      tokens: tokens!,
      isPeriodFinished: false,
      earnedAmount,
      doubleRewardAmount: earnedComplexAmount,
      stakedAmount,
      totalStakedAmount: lpInFarm,
      totalStakedInUSD: Math.round(totalStakedInUSD),
      totalRewardRate: totalRewardRate < 1 ? Math.round(totalRewardRate * 10000) / 10000 : Math.round(totalRewardRate), // Math.round(totalRewardRate),
      rewardRate: lpInFarm,
      apr: Math.round(apr), // Math.round(apr),
      apr2: 1, // Math.round(apr2),
    }
  }, [
    userInfo?.loading,
    userInfo.error,
    userInfo.result,
    pendingTokens?.loading,
    pendingTokens.error,
    pairState,
    poolInfo.error,
    poolInfo.result,
    pair,
    tokenA,
    tokenB,
    pendingCrn,
    pendingComplexRewards,
    farmId,
    tokenAPrice,
    tokenBPrice,
    totalAllocPoint.result,
    cronusPerSec.result,
    crnPrice,
    tokens,
    farm,
  ])

  return result
}

export function useFarms(): StakingCrn[] {
  const { chainId, account } = useActiveWeb3React()

  const activeFarms = STAKING.filter(
    (farm) => (chainId ? farm.chain === chainId : farm.chain === DEFAULT_FALLBACK_CHAIN) && farm.isLegacy
  )
  const contract = useMasterChefV2Contract()
  const latestBlock = useBlockNumber()

  const lpAddresses = getLPAddresses(activeFarms)
  const poolIds = getPoolIds(activeFarms)
  const lpAddressesArgs = getArgs(account, lpAddresses)

  const userInfo = useSingleContractMultipleData(lpAddressesArgs ? contract : null, 'userInfo', lpAddressesArgs) //user related
  const poolInfo = useSingleContractMultipleData(poolIds ? contract : null, 'poolInfo', poolIds)
  const allPendingTokens = useSingleContractMultipleData(
    lpAddressesArgs ? contract : null,
    'pendingTokens',
    lpAddressesArgs
  )
  const totalAllocPoint = useSingleCallResult(contract, 'totalAllocPoint')
  const cronusPerSec = useSingleCallResult(contract, 'cronusPerSec')

  // get all the info from the staking rewards contracts
  const tokens: [Token, Token][] = activeFarms.map(({ tokens }) => [tokens[0], tokens[1]])
  /* const tokensPrice: any[] = [
    {
      ...CRN[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN],
      price: useUSDCPrice(CRN[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN]),
    },
    {
      ...USDC[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN],
      price: useUSDCPrice(USDC[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN]),
    },
    {
      ...WRAPPED_NATIVE_CURRENCY[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN],
      price: useUSDCPrice(WRAPPED_NATIVE_CURRENCY[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN]),
    },
    {
      ...DAI[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN],
      price: useUSDCPrice(DAI[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN]),
    },
    {
      ...TST,
      price: useUSDCPrice(TST),
    },
  ]*/

  const pairs = usePairs(tokens)
  const lpTokens = pairs.map((pair) => pair?.[1]?.liquidityToken.address)

  const totalSupplies = useMultipleContractSingleData(lpTokens, new Interface(ERC20ABI), 'totalSupply')
  const lpInFarms = useMultipleContractSingleData(lpTokens, new Interface(ERC20ABI), 'balanceOf', [
    MASTERCHEF_ADDRESS_V2[(chainId as SupportedChainId) ?? DEFAULT_FALLBACK_CHAIN],
  ])

  const isLoading =
    userInfo?.some(({ loading }) => loading) ||
    poolInfo?.some(({ loading }) => loading) ||
    allPendingTokens?.some(({ loading }) => loading) ||
    totalSupplies?.some(({ loading }) => loading) ||
    lpInFarms?.some(({ loading }) => loading)

  const data = useMemo(() => {
    if (!chainId || !lpAddresses || !pairs) {
      return activeFarms
    }

    return lpAddresses.map((lpAddress, index) => {
      // User based info
      const userStaked = userInfo[index]
      const activeFarmID = activeFarms.find((farm) => farm.lpAddress === lpAddress)?.ID
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [pairState, pair] = pairs[index]
      const rawTotalSupply = totalSupplies[index].result?.[0] ?? JSBI.BigInt(0)
      const rawLpInFarm = lpInFarms[index].result?.[0] ?? JSBI.BigInt(0)
      if (activeFarmID === undefined) {
        return NULL_POOL
      }

      if (isLoading || pair == null) {
        return activeFarms.find((farm) => farm.ID === activeFarmID)!
      }

      const [tokenA, tokenB] = activeFarms.find((farm) => farm.ID === activeFarmID)!.tokens ?? []

      if (!contract || poolInfo[index].error || pair == null || tokenA == null || tokenB == null) {
        console.error('Failed to load staking rewards info')
        return activeFarms.find((farm) => farm.ID === activeFarmID)!
      }

      const totalSupply = CurrencyAmount.fromRawAmount(pair.liquidityToken, rawTotalSupply.toString())
      const lpInFarm = CurrencyAmount.fromRawAmount(pair.liquidityToken, rawLpInFarm.toString())

      const reserveA = tokenA ? pair?.reserveOf(tokenA) : undefined
      const reserveB = tokenB ? pair?.reserveOf(tokenB) : undefined

      const ratio = Number(lpInFarm.toFixed() ?? 0) / Number(totalSupply.toFixed() ?? 0)

      const allocPoint = JSBI.BigInt(poolInfo[index].result?.['allocPoint'] ?? 0)
      const totalAllocPointPool = JSBI.BigInt(totalAllocPoint.result?.[0] ?? 0)
      const cronusPerSecPool = JSBI.BigInt(cronusPerSec.result?.[0] ?? 0)

      const rewardRatePerSec =
        JSBI.toNumber(cronusPerSecPool) * (JSBI.toNumber(allocPoint) / (JSBI.toNumber(totalAllocPointPool) * 10 ** 18))
      const totalRewardRate = 3600 * 24 * 7 * rewardRatePerSec

      if (
        !chainId ||
        !userInfo[index] ||
        userInfo[index].error ||
        !allPendingTokens[index] ||
        allPendingTokens[index].error
      ) {
        return {
          ...activeFarms.find((farm) => farm.ID === activeFarmID)!,
          isPeriodFinished: false,
          totalStakedAmount: tokenAmount,
          totalStakedInUSD: 1,
          totalRewardRate:
            totalRewardRate < 1 ? Math.round(totalRewardRate * 10000) / 10000 : Math.round(totalRewardRate), // Math.round(totalRewardRate),
          rewardRate: tokenAmount,
          apr: 1,
          apr2: 1, // Math.round(apr2),
          rewardRatePerSec,
          reserveA,
          reserveB,
          ratio,
        }
      }
      // check for account, if no account set to 0
      const userInfoPool = JSBI.BigInt(userStaked?.result?.['amount'] ?? 0)
      const stakedAmount = CurrencyAmount.fromRawAmount(pair.liquidityToken, JSBI.BigInt(userInfoPool))

      const pendingCrn = allPendingTokens[index] ? allPendingTokens[index].result?.['pendingCronus'] : undefined
      const pendingComplexRewards = allPendingTokens[index]
        ? allPendingTokens[index].result?.['pendingBonusToken']
        : undefined

      const earnedRewardPool = JSBI.BigInt(pendingCrn ?? 0)

      const earnedAmount = CurrencyAmount.fromRawAmount(CRN[DEFAULT_FALLBACK_CHAIN], JSBI.BigInt(earnedRewardPool))
      const earnedComplexRewardPool = JSBI.BigInt(pendingComplexRewards ?? 0)

      const earnedComplexAmount = CurrencyAmount.fromRawAmount(
        activeFarms.find((farm) => farm.ID === activeFarmID)!.doubleRewardToken,
        JSBI.BigInt(earnedComplexRewardPool)
      )

      return {
        ...activeFarms.find((farm) => farm.ID === activeFarmID)!,
        isPeriodFinished: false,
        earnedAmount,
        doubleRewardAmount: earnedComplexAmount,
        stakedAmount,
        totalStakedAmount: tokenAmount,
        totalStakedInUSD: 1,
        totalRewardRate:
          totalRewardRate < 1 ? Math.round(totalRewardRate * 10000) / 10000 : Math.round(totalRewardRate), // Math.round(totalRewardRate),
        rewardRate: tokenAmount,
        apr: 1,
        apr2: 1, // Math.round(apr2),
        rewardRatePerSec,
        reserveA,
        reserveB,
        ratio,
      }
    })
  }, [
    chainId,
    lpAddresses,
    activeFarms,
    userInfo,
    pairs,
    isLoading,
    allPendingTokens,
    poolInfo,
    totalAllocPoint.result,
    cronusPerSec.result,
  ])

  return data
}

export function useSuperFarms(): StakingCrn[] {
  const { chainId } = useActiveWeb3React()

  const activeFarms = STAKING.filter(
    (farm) => !farm.isLegacy && (chainId ? chainId === farm.chain : DEFAULT_FALLBACK_CHAIN === farm.chain)
  )

  return activeFarms
}

function getLPAddresses(activeFarms: StakingCrn[]) {
  return activeFarms.map((key) => key.lpAddress)
}

function getPoolIds(activeFarms: StakingCrn[]) {
  return activeFarms.map((key) => [key.poolId.toString()])
}

function getArgs(account?: string | null, addresses?: string[]) {
  return !account || !addresses ? [] : addresses.map((_, i) => [i.toString(), account?.toString()])
}

type PartialCardResult = {
  apr: number
  apr2: number
  totalStakedInUSD: number
}

export function useFarmCardInfo(
  ratio: number,
  reserveA: CurrencyAmount<Token> | undefined,
  reserveB: CurrencyAmount<Token> | undefined,
  rewardRatePerSec: number,
  tokenA: Token,
  tokenB: Token
): PartialCardResult {
  const { chainId } = useActiveWeb3React()

  const tokenAPrice = useUSDCPrice(tokenA)
  const tokenBPrice = useUSDCPrice(tokenB)
  const crnPrice = useUSDCPrice(CRN[chainId ?? DEFAULT_FALLBACK_CHAIN])

  const result = useMemo(() => {
    if (!tokenAPrice || !tokenBPrice || !crnPrice || !reserveA || !reserveB) {
      return {
        apr: 0,
        apr2: 0,
        totalStakedInUSD: 0,
      }
    }
    const totalStakedInUSD =
      (Number(reserveA?.toFixed()) * Number(tokenAPrice?.toFixed() ?? 0) +
        Number(reserveB?.toFixed()) * Number(tokenBPrice?.toFixed() ?? 0)) *
      (!ratio ? 0 : ratio)
    const apr = getAPR(crnPrice, rewardRatePerSec, totalStakedInUSD)
    // const apr2 = getAPR(altTokenPrice, altRewardRatePerSec, totalStakedInUSD)

    return {
      apr: Math.round(apr),
      apr2: 0,
      totalStakedInUSD: Math.round(totalStakedInUSD),
    }
  }, [tokenAPrice, tokenBPrice, crnPrice])

  return result
}

type UserInfo = {
  crnStakedAmount: CurrencyAmount<Token>
  crnStakedAmountInUSD: number
  stakedAmount: CurrencyAmount<Token>
  stakedAmountInUSD: number
  userStakedInUSD: number
  earnedAmount: CurrencyAmount<Token>
  lastDepositDate: Date
  unlockDate: Date
  isUnlocked: boolean
}

type LockInfo = {
  lockIndex: number
  totalStakedInUSD: number
  apr: number
  duration: string
  durationUnix: number
  totalRewardRate: number
}
export type SuperFarmResult = Omit<StakingCrn, 'apr' | 'ratio'> & {
  crnStakedAmount: CurrencyAmount<Token>
  crnPrice: number
  clpPrice: CurrencyAmount<Token>
  totalCrnStakedAmount: CurrencyAmount<Token>
  userStakedInUSD: number
  userInfos: UserInfo[]
  lockInfos: LockInfo[]
  apr: string
  ratio: CurrencyAmount<Token>
}

export function useSuperFarm(farmId: string): SuperFarmResult {
  const { chainId, account } = useActiveWeb3React()
  const activeFarms = STAKING.filter((farm) => !farm.isLegacy)
  const farm = activeFarms.find((farm) => farm.ID === farmId)!
  const contract = useSuperFarmingContract(farm!.stakingRewardAddress)
  const locksCount = useSingleCallResult(contract, 'locksCount')

  const args = locksCount.result ? [...Array(parseInt(locksCount.result[0])).keys()].map((x) => [x]) : []
  const accArgs = locksCount.result
    ? [...Array(parseInt(locksCount.result[0])).keys()].map((x) => [x, account ?? undefined])
    : []

  const locksInfo = useSingleContractMultipleData(contract, 'lockInfo', args)

  const pendingTokensArray = useSingleContractMultipleData(contract, 'pendingTokens', accArgs) // user related
  const pendingCrn = pendingTokensArray.reduce((acc, pending) => {
    const num = pending.result?.[0] ?? undefined
    return acc + parseInt(num)
  }, 0)

  const userInfo = useSingleContractMultipleData(contract, 'userInfo', accArgs) // user related
  const totalAllocPoint = useSingleCallResult(contract, 'totalAllocPoint')
  const cronusPerSec = useSingleCallResult(contract, 'crnPerSec')
  const clpToCrnRatio = useSingleCallResult(contract, 'ratio')

  const tokens = farm?.tokens
  const [tokenA, tokenB] = tokens ?? []
  const [pairState, pair] = usePair(tokenA, tokenB)
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const lpInFarm = useTotalStakedInSuperFarm(farm!.stakingRewardAddress, pair?.liquidityToken)
  const crnInFarm = useTotalStakedInSuperFarm(farm!.stakingRewardAddress, CRN[farm!.chain])
  const tokenAPrice = useUSDCPrice(tokenA)
  const tokenBPrice = useUSDCPrice(tokenB)
  const crnPrice = useUSDCPrice(CRN[farm!.chain])

  const result = useMemo(() => {
    // Loading
    if (
      chainId == null ||
      userInfo.some((info) => info.loading) ||
      locksInfo.some((info) => info.loading) ||
      pendingTokensArray.some((pending) => pending.loading) ||
      pairState === PairState.LOADING
    ) {
      return {
        ...farm,
        crnStakedAmount: CurrencyAmount.fromRawAmount(CRN[farm!.chain], '0'),
        crnPrice: 0,
        clpPrice: CurrencyAmount.fromRawAmount(USDC[farm.chain], '0'),
        totalCrnStakedAmount: CurrencyAmount.fromRawAmount(CRN[farm!.chain], '0'),
        ratio: CurrencyAmount.fromRawAmount(new Token(tokens![0].chainId, farm!.lpAddress, 18, 'CLP'), '0'),
        userStakedInUSD: 0,
        userInfos: Array(4).fill({
          crnStakedAmount: CurrencyAmount.fromRawAmount(CRN[tokens![0].chainId], '0'),
          crnStakedAmountInUSD: 0,
          stakedAmount: CurrencyAmount.fromRawAmount(new Token(tokens![0].chainId, farm!.lpAddress, 18, 'CLP'), '0'),
          stakedAmountInUSD: 0,
          userStakedInUSD: 0,
          earnedAmount: CurrencyAmount.fromRawAmount(CRN[tokens![0].chainId], '0'),
          lastDepositDate: new Date(Date.now()),
          unlockDate: new Date(Date.now()),
          isUnlocked: false,
        }),
        lockInfos: Array.from(Array(4).keys()).map((l) => {
          return {
            lockIndex: l,
            totalStakedInUSD: 0,
            apr: 0,
            durationUnix: 0,
            duration: 'No vesting',
            totalRewardRate: 1,
          }
        }),
        apr: '0',
      }
    }

    // Error

    if (
      userInfo.some((info) => info.error) ||
      locksInfo.some((info) => info.error) ||
      pendingTokensArray.some((pending) => pending.error) ||
      pair == null ||
      // stakingInfoData?.[farmId] == null ||
      tokenA == null ||
      tokenB == null ||
      !totalSupply ||
      !lpInFarm ||
      !crnInFarm
    ) {
      console.error('Failed to load staking rewards info')
      return {
        ...farm,
        crnPrice: 0,
        clpPrice: CurrencyAmount.fromRawAmount(USDC[farm.chain], '0'),
        crnStakedAmount: CurrencyAmount.fromRawAmount(CRN[farm!.chain], '0'),
        totalCrnStakedAmount: CurrencyAmount.fromRawAmount(CRN[farm!.chain], '0'),
        ratio: CurrencyAmount.fromRawAmount(new Token(tokens![0].chainId, farm!.lpAddress, 18, 'CLP'), '0'),
        userStakedInUSD: 0,
        userInfos: Array(4).fill({
          crnStakedAmount: CurrencyAmount.fromRawAmount(CRN[tokens![0].chainId], '0'),
          crnStakedAmountInUSD: 0,
          stakedAmount: CurrencyAmount.fromRawAmount(new Token(tokens![0].chainId, farm!.lpAddress, 18, 'CLP'), '0'),
          stakedAmountInUSD: 0,
          userStakedInUSD: 0,
          earnedAmount: CurrencyAmount.fromRawAmount(CRN[tokens![0].chainId], '0'),
          lastDepositDate: new Date(Date.now()),
          unlockDate: new Date(Date.now()),
          isUnlocked: false,
        }),
        lockInfos: Array.from(Array(4).keys()).map((l) => {
          return {
            lockIndex: l,
            totalStakedInUSD: 0,
            apr: 0,
            durationUnix: 0,
            duration: 'No vesting',
            totalRewardRate: 1,
          }
        }),
        apr: '0',
      }
    }

    // const { totalStakedInUSD, totalRewardRate, apr, apr2 } = stakingInfoData[farmId] // @TODO: Restore when we have a fetcher
    const reserveA = tokenA ? pair?.reserveOf(tokenA) : undefined
    const reserveB = tokenB ? pair?.reserveOf(tokenB) : undefined
    // const tokenBperLP = lpInFarm ? Number(reserveB?.toFixed()) / Number(lpInFarm.toFixed()) : undefined
    // const tokenAperLP = lpInFarm ? Number(reserveA?.toFixed()) / Number(lpInFarm.toFixed()) : undefined

    const reserveAInUSD = Number(reserveA?.toFixed()) * Number(tokenAPrice?.toFixed() ?? 0)
    const reserveBInUSD = Number(reserveB?.toFixed()) * Number(tokenBPrice?.toFixed() ?? 0)

    const clpStakedInUSD =
      ((reserveAInUSD + reserveBInUSD) * Number(lpInFarm.toFixed())) / Number(totalSupply.toFixed())

    const clpPrice = CurrencyAmount.fromRawAmount(USDC[farm.chain], '1000000')
      .divide(totalSupply)
      .multiply(
        parseUnits(String(reserveAInUSD))
          .add(parseUnits(String(reserveBInUSD)))
          .toString()
      )
    const totalAllocPointPool = JSBI.BigInt(totalAllocPoint.result?.[0] ?? 0)
    const cronusPerSecPool = parseInt(cronusPerSec.result?.[0]) / 10 ** 18 ?? 0

    const statsByLock = locksInfo.map((lock, i) => {
      const lockLp = parseInt(lock.result?.['totalCLP']) / 10 ** 18
      const lockCrn = parseInt(lock.result?.['totalDeposit']) / 10 ** 18
      const allocPoint = JSBI.BigInt(lock.result?.['allocPoint'] ?? 0)

      const lockLpStakedInUSD = ((reserveAInUSD + reserveBInUSD) * Number(lockLp)) / Number(totalSupply.toFixed())
      const lockCrnStakedInUSD = Number(lockCrn) * Number(crnPrice?.toFixed() ?? 0)
      const lockTotalStakedInUSD = lockLpStakedInUSD + lockCrnStakedInUSD
      const rewardRatePerSec = cronusPerSecPool * (JSBI.toNumber(allocPoint) / JSBI.toNumber(totalAllocPointPool))

      const totalRewardRate = 3600 * 24 * 7 * rewardRatePerSec

      const apr = getAPR(crnPrice, rewardRatePerSec, lockTotalStakedInUSD ?? 0)

      const duration = parseInt(lock.result?.['lockDuration'])

      const parsedDuration: { [period: string]: number } = {
        months: Math.floor(duration / (60 * 60 * 24 * 30)),
        days: Math.floor(duration / (60 * 60 * 24)),
        hours: Math.floor((duration / (60 * 60)) % 24),
        minutes: Math.floor((duration / 60) % 60),
        seconds: Math.floor(duration % 60),
      }

      const readableDuration: { [period: string]: string } = {
        months: parsedDuration.months
          ? String(parsedDuration.months) + (parsedDuration.months === 1 ? ' month' : ' months')
          : '',
        days: parsedDuration.days ? String(parsedDuration.days) + (parsedDuration.days === 1 ? ' day' : ' days') : '',
        hours: parsedDuration.hours
          ? String(parsedDuration.hours) + (parsedDuration.hours === 1 ? ' hour' : ' hours')
          : '',
        minutes: parsedDuration.minutes
          ? String(parsedDuration.minutes) + (parsedDuration.minutes === 1 ? ' minute' : ' minutes')
          : '',
        seconds: parsedDuration.seconds
          ? String(parsedDuration.seconds) + (parsedDuration.seconds === 1 ? ' second' : ' seconds')
          : '',
      }
      const period = Object.keys(readableDuration).find((i) => !!readableDuration[i] === true)
      return {
        lockIndex: i,
        totalStakedInUSD: lockTotalStakedInUSD ? Math.floor(lockTotalStakedInUSD) : 0,
        apr: apr ? Math.floor(apr) : 0,
        durationUnix: duration,
        duration: readableDuration[period ?? 'days'] ?? 'No vesting',
        totalRewardRate:
          totalRewardRate < 1 ? Math.round(totalRewardRate * 10000) / 10000 : Math.round(totalRewardRate),
      }
    })

    const totalStakedInUSD = statsByLock.reduce((acc, lock) => {
      return acc + lock.totalStakedInUSD
    }, 0)

    const userStatsByLock = userInfo.map((lock, i) => {
      const crnStakedAmount = CurrencyAmount.fromRawAmount(
        CRN[farm.chain],
        JSBI.BigInt(lock.result?.['crnAmount'] ?? 0)
      )
      const crnStakedAmountInUSD = Number(crnStakedAmount.toFixed()) * Number(crnPrice?.toFixed())

      const stakedAmount = CurrencyAmount.fromRawAmount(
        pair.liquidityToken,
        JSBI.BigInt(lock.result?.['clpAmount'] ?? 0)
      )
      const clpStakedAmountInUSD = (Number(stakedAmount.toFixed()) / Number(lpInFarm.toFixed())) * clpStakedInUSD
      const stakedAmountInUSD = Math.floor(crnStakedAmountInUSD + clpStakedAmountInUSD)

      const earnedAmount = CurrencyAmount.fromRawAmount(
        CRN[farm.chain],
        JSBI.BigInt(pendingTokensArray[i].result?.[0] ?? 0)
      )

      const lastDeposit = parseInt(lock.result?.['lastDeposit'])
      const lastDepositDate = new Date(lastDeposit * 1000)
      const unlockDate = new Date(lastDepositDate.getTime() + statsByLock[i].durationUnix * 1000)
      const isUnlocked = Date.now() > unlockDate.getTime()

      return {
        crnStakedAmount,
        crnStakedAmountInUSD,
        stakedAmount,
        stakedAmountInUSD: stakedAmountInUSD ? stakedAmountInUSD : 0,
        userStakedInUSD: crnStakedAmountInUSD + stakedAmountInUSD ? stakedAmountInUSD : 0,
        earnedAmount,
        lastDepositDate,
        unlockDate,
        isUnlocked,
      }
    })

    const sortByApr = [...statsByLock].sort((a, b) => a.apr - b.apr)

    const stakedAmount = userStatsByLock.reduce((acc, lock) => {
      return acc.add(lock.stakedAmount)
    }, CurrencyAmount.fromRawAmount(pair.liquidityToken, '0'))

    const crnStakedAmount = userStatsByLock.reduce((acc, lock) => {
      return acc.add(lock.crnStakedAmount)
    }, CurrencyAmount.fromRawAmount(CRN[farm.chain], '0'))

    const earnedAmount = userStatsByLock.reduce((acc, lock) => {
      return acc.add(lock.earnedAmount)
    }, CurrencyAmount.fromRawAmount(CRN[farm.chain], '0'))

    const userStakedInUSD = userStatsByLock.reduce((acc, lock) => {
      return acc + lock.userStakedInUSD
    }, 0)
    const ratio = CurrencyAmount.fromRawAmount(pair.liquidityToken, clpToCrnRatio.result?.[0] ?? '0')
    return {
      ...farm,
      tokens: tokens!,
      isPeriodFinished: false,
      crnPrice: crnPrice ? +crnPrice?.toFixed() : 0,
      clpPrice: clpPrice ? clpPrice : CurrencyAmount.fromRawAmount(USDC[farm.chain], '0'),
      earnedAmount,
      stakedAmount,
      crnStakedAmount,
      totalStakedAmount: lpInFarm,
      totalCrnStakedAmount: crnInFarm,
      userStakedInUSD,
      totalStakedInUSD: Math.round(totalStakedInUSD),
      rewardRate: lpInFarm,
      userInfos: userStatsByLock,
      lockInfos: statsByLock,
      totalRewardRate: Math.floor(3600 * 24 * 7 * cronusPerSecPool),
      ratio,
      apr: `${sortByApr[0]?.apr}% - ${sortByApr[statsByLock.length - 1]?.apr}`,
    }
  }, [
    chainId,
    userInfo,
    pairState,
    pair,
    tokenA,
    tokenB,
    pendingCrn,
    activeFarms,
    farmId,
    tokenAPrice,
    tokenBPrice,
    totalAllocPoint.result,
    cronusPerSec.result,
    crnPrice,
    tokens,
    farm,
  ])

  return result
}
/*
export function useFarms(): StakingCrn[] {
  const { chainId } = useActiveWeb3React()

  const activeFarms = STAKING[(chainId as SupportedChainId) ?? SupportedChainId.RINKEBY]
  const farms = useFarmsAPI()
  const stakingInfo = useMultipleFarms()

  const stakingInfoMap = useMemo(
    () =>
      stakingInfo.reduce((acc, item) => {
        acc.set(item.ID, item)
        return acc
      }, new Map()),
    [stakingInfo]
  )

  const farmsMap = useMemo(
    () =>
      farms.reduce((acc, item) => {
        acc.set(item.ID, item)
        return acc
      }, new Map()),
    [farms]
  )

  const result = useMemo(
    () =>
      activeFarms.reduce<StakingCrn[]>((acc, farm) => {
        const farmID = farm.ID
        const farmResult = farmsMap.has(farmID) ? farmsMap.get(farmID) : farm

        if (stakingInfoMap.has(farmID)) {
          const { stakedAmount } = stakingInfoMap.get(farmID)
          farmResult.stakedAmount = stakedAmount
        }

        acc.push(farmResult)

        return acc
      }, []),
    [activeFarms, farmsMap, stakingInfoMap]
  )

  return result
}
*/
