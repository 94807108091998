import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import FixedCurrencyInputPanel from 'components/TetraPool/FixedCurrencyInputPanel'
import TetraCurrencyLogo from 'components/TetraPool/TetraLogo'
import { POOLS_MAP } from 'constants/tetrapools'
import { parseUnits } from 'ethers/lib/utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import { PermitInfo, PermitType } from 'hooks/useERC20Permit'
import useGetImbalance from 'hooks/useGetImbalance'
import useGetProportional from 'hooks/useGetProportional'
import { useTokenFormState } from 'hooks/useTokenFormState'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactElement, useCallback, useContext, useMemo, useState } from 'react'
import { ArrowDown, Plus } from 'react-feather'
import { Text } from 'rebass'
import { useDerivedTetraBurnInfo, useTetraContract } from 'state/tetraswap/hooks'
import { ThemeContext } from 'styled-components/macro'
import { calculateSlippageAndParse } from 'utils/calculateSlippageAndParse'
import { maxAmountSpend } from 'utils/maxAmountSpend'

import { ButtonConfirmed, ButtonError, ButtonPrimary } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import CurrencyLogo from '../../components/CurrencyLogo'
// import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import Slider from '../../components/Slider'
import { Dots } from '../../components/swap/styleds'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useTokenContract } from '../../hooks/useContract'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { ClickableText, MaxButton, Wrapper } from '../Pool/styleds'

const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)

enum WithdrawState {
  FROM_LP = 'FROM_LP',
  ONE_TOKEN = 'ONE_TOKEN',
  IMBALANCE = 'IMBALANCE',
}

export default function RemoveLiquidity({ poolId }: { poolId: string }): ReactElement | null {
  const POOL = POOLS_MAP[poolId]

  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const { poolTokens: tokens, lpToken } = POOL

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const [tokenFormState, updateTokenFormState] = useTokenFormState([lpToken, ...tokens])
  const typedTokenValues = useMemo(() => {
    tokens.reduce((a, t) => {
      if (t.address === lpToken.address) {
        return a
      }
      return {
        ...a,
        [t.address]: tokenFormState[t.address],
      }
    }, {})
  }, [lpToken, tokenFormState, tokens])

  const [withdrawState, setWithdrawState] = useState<WithdrawState>(WithdrawState.FROM_LP)

  const { parsedAmounts, minAmounts, error, userData, poolInfo } = useDerivedTetraBurnInfo(poolId, tokenFormState)

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const blockTimestamp = useCurrentBlockTimestamp()

  /* useEffect(() => {
    const reactBlockChange = async () => {
      let newState = tokenFormState
      if (withdrawState === WithdrawState.FROM_LP) {
        await getProportional(tokenFormState[lpToken.address]).then((response: any) => {
          newState = [lpToken, ...tokens].reduce((a, t) => {
            if (t.address === lpToken.address) {
              return { ...a, [t.address]: tokenFormState[lpToken.address] }
            }
            const derivedValue = response?.[t.address] ?? CurrencyAmount.fromRawAmount(t, '0')
            return {
              ...a,
              [t.address]: derivedValue.toExact(),
            }
          }, {})
        })
      }
      if (withdrawState === WithdrawState.ONE_TOKEN) {
        const tokenKeys = Object.keys(poolTokensState).filter((key: string) => {
          return poolTokensState[key] > 0
        })
      }
      updateTokenFormState(newState)
    }

    reactBlockChange()
  }, [blockTimestamp])
  */
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  // pair contract
  const lpContract: Contract | null = useTokenContract(lpToken.address)

  const swapContract = useTetraContract(poolId)
  const getProportional = useGetProportional(poolId)
  const getImbalance = useGetImbalance(poolId)

  const REMOVE_TETRA_LIQUIDITY_PERMIT_INFO: PermitInfo = {
    version: '1',
    name: 'Cronus Stable LP',
    type: PermitType.AMOUNT,
  }

  // allowance handling
  /*const { gatherPermitSignature, signatureData } = useERC20Permit(
    parsedAmounts[lpToken.address],
    swapContract?.address,
    deadline,
    REMOVE_TETRA_LIQUIDITY_PERMIT_INFO
  )*/

  const [approval, approveCallback] = useApproveCallback(
    tryParseCurrencyAmount(tokenFormState[lpToken.address], lpToken),
    swapContract?.address
  )

  async function onAttemptToApprove() {
    if (!lpContract || !library || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[lpToken.address]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    await approveCallback()
  }

  const maxAmounts: { [k: string]: CurrencyAmount<Token> } = [lpToken, ...tokens].reduce((accumulator, token) => {
    const max = maxAmountSpend(userData.maxAmounts[token.address])
    return {
      ...accumulator,
      [token.address]: max,
    }
  }, {})
  const isValid = !error || parsedAmounts[lpToken.address].greaterThan(maxAmounts[lpToken.address])

  const [innerPercentage, setPercentage] = useState(0)

  type updateTokenFormValueReturn = (address: string, value: string, max?: boolean | undefined) => Promise<void>
  const updateTokenFormValue: updateTokenFormValueReturn = useCallback(
    async (address: string, value: string, max?: boolean) => {
      const initialNewState = { [address]: value }
      updateTokenFormState(initialNewState)

      let newState
      const addProportional = address === lpToken.address
      if (addProportional) {
        await getProportional(value).then((response: any) => {
          newState = [lpToken, ...tokens].reduce((a, t) => {
            if (t.address === address) {
              return a
            }
            const derivedValue = response?.[t.address] ?? CurrencyAmount.fromRawAmount(t, '0')
            return {
              ...a,
              [t.address]: derivedValue.toExact(),
            }
          }, {})
          setWithdrawState(WithdrawState.FROM_LP)
          const percentage = new Percent(
            tryParseCurrencyAmount(value, lpToken)?.quotient ?? '0',
            maxAmounts[lpToken.address].quotient
          )
          if (+maxAmounts[lpToken.address].toExact() > 0) {
            setPercentage(Math.ceil(+percentage.toFixed()))
          } else {
            setPercentage(100)
          }
        })
      }
      if (max && !addProportional) {
        newState = tokens.reduce((a, t) => {
          if (t.address === address) {
            return {
              ...a,
              [t.address]: value,
              [lpToken.address]: maxAmounts[lpToken.address].toExact(),
            }
          }
          const derivedValue = '0'
          return {
            ...a,
            [t.address]: derivedValue,
          }
        }, {})
        setPercentage(100)
        setWithdrawState(WithdrawState.ONE_TOKEN)
      }
      if (!max && !addProportional) {
        await getImbalance({ ...tokenFormState, ...initialNewState }).then((response: any) => {
          newState = {
            [lpToken.address]: calculateSlippageAndParse(response, allowedSlippage, true).typed,
          }
          const percentage = new Percent(response?.quotient, maxAmounts[lpToken.address].quotient)
          if (+response.toExact() > 0 && +maxAmounts[lpToken.address].toExact() > 0) {
            setPercentage(Math.ceil(+percentage.toFixed()))
          } else {
            setPercentage(100)
          }
          setWithdrawState(WithdrawState.IMBALANCE)
        })
        /*         const amountAsCurrency =
          tryParseCurrencyAmount(
            value,
            tokens.find((el) => el.address === address)
          ) ?? CurrencyAmount.fromRawAmount(tokens.find((el) => el.address === address)!, '0')
        const percentage = new Percent(amountAsCurrency?.quotient ?? '0', maxAmounts[lpToken.address].quotient ?? '100')
        const lpDerivedAmount = amountAsCurrency.greaterThan(maxAmounts[address])
          ? CurrencyAmount.fromRawAmount(lpToken, '0')
          : maxAmounts[lpToken.address].multiply(percentage)
        newState = tokens.reduce((a, t) => {
          if (t.address === address) {
            return {
              ...a,
              [t.address]: value,
              [lpToken.address]: calculateSlippageAndParse(lpDerivedAmount, allowedSlippage).typed,
            }
          }
          const derivedValue = ''
          return {
            ...a,
            [t.address]: derivedValue,
          }
        }, {})
        if (
          +lpDerivedAmount.toExact() > 0 &&
          +maxAmounts[lpToken.address].toExact() > 0 &&
          +lpDerivedAmount.toExact() <= +maxAmounts[lpToken.address].toExact()
        ) {
          setPercentage(Math.ceil(+percentage.toFixed()))
        } else {
          setPercentage(100)
        } */
      }

      updateTokenFormState(newState ?? initialNewState)
    },
    [allowedSlippage, getImbalance, getProportional, lpToken, maxAmounts, tokenFormState, tokens, updateTokenFormState]
  )

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      setPercentage(value)
      updateTokenFormValue(
        lpToken.address,
        maxAmounts[lpToken.address].divide('100').multiply(String(value)).toExact(),
        value === 100
      )
    },
    [lpToken.address, maxAmounts, updateTokenFormValue]
  )

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    innerPercentage,
    liquidityPercentChangeCallback
  )

  const liquidityPercentChange = async (value: number) => {
    setInnerLiquidityPercentage(value)
  }
  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account || !deadline || !swapContract) throw new Error('missing dependencies')
    setAttemptingTxn(true)

    const poolTokensState: { [k: string]: number } = tokens.reduce((a, t) => {
      return { ...a, [t.address]: +tokenFormState[t.address] }
    }, {})

    const tokenKeys = Object.keys(poolTokensState).filter((key: string) => {
      return poolTokensState[key] > 0
    })

    if (swapContract && minAmounts && deadline) {
      if (withdrawState === WithdrawState.FROM_LP) {
        await swapContract
          .removeLiquidity(
            parseUnits(parsedAmounts[lpToken.address].toExact()),
            tokens.map((t) => {
              return parseUnits(minAmounts[t.address].toExact())
            }),
            deadline
          )
          .then((response: TransactionResponse) => {
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.REMOVE_LIQUIDITY_TETRA_POOL,
              poolName: poolInfo.poolName ?? undefined,
              lpTokensBurned: tokenFormState[lpToken.address],
            })
            setTxHash(response.hash)
            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
          .catch((e: any) => {
            console.log(e)
            setAttemptingTxn(false)
            setShowConfirm(false)

            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
      }
      if (withdrawState === WithdrawState.ONE_TOKEN || (tokenKeys.length < 2 && tokenKeys.length > 0)) {
        await swapContract
          .removeLiquidityOneToken(
            parseUnits(parsedAmounts[lpToken.address].toExact()),
            tokens.findIndex((token) => {
              return token.address === tokenKeys[0]
            }),
            parseUnits(parsedAmounts[tokenKeys[0]].toExact()),
            deadline
          )
          .then((response: TransactionResponse) => {
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.REMOVE_LIQUIDITY_TETRA_POOL,
              poolName: poolInfo.poolName ?? undefined,
              lpTokensBurned: tokenFormState[lpToken.address],
            })
            setTxHash(response.hash)
            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
          .catch((e: any) => {
            console.log(e)
            setAttemptingTxn(false)
            setShowConfirm(false)

            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
      }
      if (withdrawState === WithdrawState.IMBALANCE && !(tokenKeys.length < 2) && tokenKeys.length > 0) {
        const parsedTokenAmounts = tokens.map((t) => {
          return parseUnits(parsedAmounts[t.address].toExact())
        })
        await swapContract
          .removeLiquidityImbalance(parsedTokenAmounts, parseUnits(parsedAmounts[lpToken.address].toExact()), deadline)
          .then((response: TransactionResponse) => {
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.REMOVE_LIQUIDITY_TETRA_POOL,
              poolName: poolInfo.poolName ?? undefined,
              lpTokensBurned: tokenFormState[lpToken.address],
            })
            setTxHash(response.hash)
            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
          .catch((e: any) => {
            console.log(e)
            setAttemptingTxn(false)
            setShowConfirm(false)

            // Clear input after deposit
            updateTokenFormState(
              [lpToken, ...tokens].reduce(
                (acc, t) => ({
                  ...acc,
                  [t.address]: '',
                }),
                {}
              )
            )
          })
      }
    } else {
      setAttemptingTxn(false)
    }
  }

  function modalHeader() {
    const returnedTokens = tokens.filter((t) => {
      if (+parsedAmounts[t.address].toExact() > 0) {
        return true
      }
      return false
    })

    return (
      <AutoColumn gap={'md'} style={{ padding: '1rem' }}>
        {returnedTokens.map((token, index) => (
          <div key={`${token.symbol}-parsed-recap`}>
            <RowBetween align="flex-center">
              <Text fontSize={24} fontWeight={500}>
                {parsedAmounts[token.address]?.toFixed(6)}
              </Text>
              <RowFixed gap="4px">
                <CurrencyLogo currency={token} size={'24px'} />
                <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                  {token.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
            {index !== returnedTokens.length - 1 && (
              <RowFixed marginTop={'12px'}>
                <Plus size="16" color={theme.text2} />
              </RowFixed>
            )}
          </div>
        ))}

        <ThemedText.Italic fontSize={12} color={theme.text2} textAlign="left" padding={'12px 0 0 0'}>
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </ThemedText.Italic>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        <div style={{ padding: '1rem' }}>
          <RowBetween>
            <Text color={theme.text2} fontWeight={500} fontSize={16}>
              <Trans>Tetrapool SCLP Burned</Trans>
            </Text>
            <RowFixed>
              <TetraCurrencyLogo
                currency0={tokens[0]}
                currency1={tokens[1]}
                currency2={tokens[2]}
                currency3={tokens[3]}
                margin={true}
              />
              <Text fontWeight={500} fontSize={16}>
                {parsedAmounts[lpToken.address].toFixed(6)}
              </Text>
            </RowFixed>
          </RowBetween>
        </div>
        <ButtonPrimary disabled={!(approval === ApprovalState.APPROVED)} onClick={onRemove}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Confirm</Trans>
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = (
    <Trans>
      Removing
      {tokens.map((token) => {
        if (parsedAmounts[token.address].greaterThan('0')) {
          return `${parsedAmounts[token.address].toFixed(6)} ${token.symbol}`
        }
        return ''
      })}
    </Trans>
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      updateTokenFormState(
        [lpToken, ...tokens].reduce(
          (acc, t) => ({
            ...acc,
            [t.address]: '',
          }),
          {}
        )
      )
    }
    setTxHash('')
  }, [lpToken, tokens, txHash, updateTokenFormState])

  return (
    <>
      <Wrapper>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash ? txHash : ''}
          content={() => (
            <ConfirmationModalContent
              title={<Trans>You will receive</Trans>}
              onDismiss={handleDismissConfirmation}
              topContent={modalHeader}
              bottomContent={modalBottom}
            />
          )}
          pendingText={pendingText}
        />
        <AutoColumn gap="md">
          <LightCard>
            <AutoColumn gap="20px">
              <RowBetween>
                <Text fontWeight={500}>
                  <Trans>Remove Amount</Trans>
                </Text>
                {true && (
                  <ClickableText
                    fontWeight={500}
                    onClick={() => {
                      setShowDetailed(!showDetailed)
                    }}
                  >
                    {showDetailed ? <Trans>Simple</Trans> : <Trans>Detailed</Trans>}
                  </ClickableText>
                )}
              </RowBetween>
              <Row style={{ alignItems: 'flex-end' }}>
                <Text fontSize={72} fontWeight={500}>
                  {innerLiquidityPercentage}%
                </Text>
              </Row>
              {!showDetailed && (
                <>
                  <Slider value={innerLiquidityPercentage} onChange={liquidityPercentChange} />
                  <RowBetween>
                    <MaxButton onClick={() => liquidityPercentChange(25)} width="20%">
                      25%
                    </MaxButton>
                    <MaxButton onClick={() => liquidityPercentChange(50)} width="20%">
                      50%
                    </MaxButton>
                    <MaxButton onClick={() => liquidityPercentChange(75)} width="20%">
                      75%
                    </MaxButton>
                    <MaxButton onClick={() => liquidityPercentChange(100)} width="20%">
                      Max
                    </MaxButton>
                  </RowBetween>
                </>
              )}
            </AutoColumn>
          </LightCard>
          {!showDetailed && (
            <>
              <ColumnCenter>
                <ArrowDown size="16" color={theme.text2} />
              </ColumnCenter>
              <LightCard>
                <AutoColumn gap="10px">
                  {tokens.map((token) => (
                    <RowBetween key={`remove-liquidity-${token.symbol}-detail`}>
                      <Text fontSize={24} fontWeight={500}>
                        {+parsedAmounts[token.address].toFixed(6) || '-'}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={token} style={{ marginRight: '12px' }} />
                        <Text fontSize={24} fontWeight={500} id="remove-liquidity-tokena-symbol">
                          {token.symbol}
                        </Text>
                      </RowFixed>
                    </RowBetween>
                  ))}
                </AutoColumn>
              </LightCard>
            </>
          )}

          {showDetailed && (
            <>
              <FixedCurrencyInputPanel
                value={tokenFormState[lpToken.address]}
                onUserInput={(value) => {
                  updateTokenFormValue(lpToken.address, value)
                }}
                currency={lpToken}
                id={`tetraswap-currency-${lpToken.symbol}-input`}
                onMax={() => {
                  updateTokenFormValue(lpToken.address, maxAmounts[lpToken.address].toExact(), true)
                }}
                disableMaxButton={tokenFormState[lpToken.address] === maxAmounts[lpToken.address].toExact()}
                renderBalance
                renderLogo={
                  <TetraCurrencyLogo
                    currency0={tokens[0]}
                    currency1={tokens[1]}
                    currency2={tokens[2]}
                    currency3={tokens[3]}
                    size={20}
                  />
                }
              />
              <ColumnCenter>
                <ArrowDown size="16" color={theme.text2} />
              </ColumnCenter>
              {tokens.map((token, index) => (
                <div key={`tetraswap-currency-${index}-input`}>
                  <FixedCurrencyInputPanel
                    value={tokenFormState[token.address]}
                    onUserInput={(value) => {
                      updateTokenFormValue(token.address, value)
                    }}
                    currency={token}
                    id={`tetraswap-currency-${index}-input`}
                    onMax={() => {
                      updateTokenFormValue(token.address, maxAmounts[token.address].toExact(), true)
                    }}
                    disableMaxButton={tokenFormState[token.address] === maxAmounts[token.address].toExact()}
                  />
                  {index !== tokens.length - 1 && (
                    <ColumnCenter>
                      <Plus size="16" color={theme.text2} />
                    </ColumnCenter>
                  )}
                </div>
              ))}
            </>
          )}
        </AutoColumn>
      </Wrapper>
      <div style={{ position: 'relative' }}>
        {!account ? (
          <ButtonPrimary onClick={toggleWalletModal}>
            <Trans>Connect Wallet</Trans>
          </ButtonPrimary>
        ) : (
          <RowBetween>
            {approval !== ApprovalState.APPROVED && approval !== ApprovalState.UNKNOWN && (
              <ButtonConfirmed onClick={onAttemptToApprove} fontWeight={500} fontSize={18}>
                {approval === ApprovalState.PENDING ? (
                  <Dots>
                    <Trans>Approving</Trans>
                  </Dots>
                ) : (
                  <Trans>Approve</Trans>
                )}
              </ButtonConfirmed>
            )}
            {approval !== ApprovalState.NOT_APPROVED && approval !== ApprovalState.PENDING && (
              <ButtonError
                onClick={() => {
                  setShowConfirm(true)
                }}
                disabled={
                  !isValid ||
                  approval !== ApprovalState.APPROVED ||
                  parsedAmounts[lpToken.address].greaterThan(maxAmounts[lpToken.address])
                }
                error={!isValid && Object.values(parsedAmounts).every((amount: any) => !!amount)}
              >
                <Text fontSize={18} fontWeight={500}>
                  {<Trans>Remove</Trans>}
                </Text>
              </ButtonError>
            )}
          </RowBetween>
        )}
      </div>
    </>
  )
}
