import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import STABLECOIN_IMAGE from 'assets/images/stablecoins.png'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import { TetraTabs } from 'components/NavigationTabs'
import { AutoRow, RowBetween } from 'components/Row'
import SettingsTab from 'components/Settings'
import { POOLS_MAP } from 'constants/tetrapools'
import AppBody from 'pages/AppBody'
import DepositTetra from 'pages/DepositTetra'
import SwapTetra from 'pages/SwapTetra'
import WithdrawTetra from 'pages/WithdrawTetra'
import { ReactElement, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

const PageWrapper = styled(AutoRow)`
  max-width: 1200px;
  width: 100%;
`

export enum TetraPoolPageState {
  SWAP = 'SWAP',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

const DEFAULT_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

export default function ManageTetraPool({
  history,
  match: {
    params: { poolId },
  },
}: RouteComponentProps<{ poolId: string }>): ReactElement | null {
  const POOL = POOLS_MAP[poolId]
  if (!POOL) {
    history.replace('/tetrapools')
  }
  const [page, setPage] = useState<TetraPoolPageState>(TetraPoolPageState.SWAP)
  const { primary1 } = useTheme()

  return (
    <PageWrapper gap="lg" justify="center" align="start">
      <div>
        <AppBody>
          <TetraTabs adding={true} active={page} onChange={setPage} />
          <RowBetween padding={'1.5rem 1rem 1rem 1rem'}>
            <ThemedText.MediumHeader>
              {page === TetraPoolPageState.SWAP && <Trans>Swap</Trans>}
              {page === TetraPoolPageState.DEPOSIT && <Trans>Deposit</Trans>}
              {page === TetraPoolPageState.WITHDRAW && <Trans>Withdraw</Trans>}
            </ThemedText.MediumHeader>
            {page === TetraPoolPageState.SWAP && <SettingsTab placeholderSlippage={DEFAULT_SLIPPAGE_TOLERANCE} />}
          </RowBetween>
          {page === TetraPoolPageState.SWAP && <SwapTetra poolId={poolId} />}
          {page === TetraPoolPageState.DEPOSIT && <DepositTetra poolId={poolId} />}
          {page === TetraPoolPageState.WITHDRAW && <WithdrawTetra poolId={poolId} />}
        </AppBody>
        <LightCard
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '1rem 1rem',
            maxWidth: '480px',
            marginTop: '1rem',
            boxShadow:
              '0px 0px 1px rgb(0 0 0 / 1%), 0px 4px 8px rgb(0 0 0 / 4%), 0px 16px 24px rgb(0 0 0 / 4%), 0px 24px 32px rgb(0 0 0 / 1%)',
          }}
        >
          <ThemedText.Body fontSize={14} style={{ fontWeight: 500 }} textAlign="left">
            <Trans>
              Cronus Tetraswap pools are specialized utilities that enable users to swap and provide liquidity between
              four stable assets with ease and limited slippage.
            </Trans>
          </ThemedText.Body>
          <ThemedText.Small fontStyle="italic" fontWeight="400" fontSize="14px" textAlign="left" paddingTop="0.5rem">
            Tetra from the greek τετρα, meaning four.
          </ThemedText.Small>
        </LightCard>
      </div>
      <AutoColumn gap="md" justify="center" style={{ flex: '1 1 50%', height: '100%', marginTop: '1rem' }}>
        <ThemedText.MediumHeader fontSize="40px">{POOL.poolName}</ThemedText.MediumHeader>
        {/* <ThemedText.MediumHeader style={{ textTransform: 'uppercase' }}>{POOL.subtitle}</ThemedText.MediumHeader> */}
        <ThemedText.Black textAlign="center" color={primary1}>
          {POOL.poolTokens.map((t, i) => t.symbol + (i < POOL.poolTokens.length - 1 ? '-' : ''))}
        </ThemedText.Black>
        <div style={{ marginBottom: '1rem', padding: '0 5rem' }}>
          <ThemedText.Black textAlign="center">{POOL.description}</ThemedText.Black>
        </div>
        <div style={{ maxWidth: '300px' }}>
          <img alt="" src={POOL.poolImage || STABLECOIN_IMAGE} style={{ maxWidth: '300px', objectFit: 'cover' }} />
        </div>
      </AutoColumn>
    </PageWrapper>
  )
}
