import { Token } from '@uniswap/sdk-core'
import { DEFAULT_FALLBACK_CHAIN, SupportedChainId } from 'constants/chains'

type Props = {
  lpAddress: string
  chainId?: SupportedChainId
  token0: Token
  token1: Token
}

export default function useCLP({ lpAddress, chainId = DEFAULT_FALLBACK_CHAIN, token0, token1 }: Props) {
  return new Token(chainId, lpAddress, 18, 'CLP', `CLP ${token0?.symbol}-${token1?.symbol}`)
}
