import { Locked16, Unlocked16 } from '@carbon/icons-react'
import { TransactionResponse } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { CRN, USDC } from 'constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ClickableText } from 'pages/Pool/styleds'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { SuperFarmResult } from 'state/stake/user-farms'
import styled, { useTheme } from 'styled-components/macro'

import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useCLP from '../../hooks/useCLP'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { useSuperFarmingContract } from '../../state/stake/hooks-sushi'
import { TransactionType } from '../../state/transactions/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { CloseIcon, ThemedText } from '../../theme'
import { formatCurrencyAmount } from '../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import CurrencyInputPanel from '../CurrencyInputPanel'
import Modal from '../Modal'
import { LoadingView, SubmittedView } from '../ModalViews'
import Row, { RowBetween } from '../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
const StakeClickableText = styled(ClickableText)<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.primary1 : theme.bg5)};
`

interface ManageModalProps {
  isOpen: boolean
  onDismiss: () => void
  lockIndex: number
  stakingInfo: SuperFarmResult
  userLiquidityUnstaked: CurrencyAmount<Token> | undefined
  userCrnUnstaked: CurrencyAmount<Token> | undefined
  unstake?: boolean
}

export default function ManageModal({
  isOpen,
  onDismiss,
  lockIndex,
  stakingInfo,
  userLiquidityUnstaked,
  userCrnUnstaked,
  unstake = false,
}: ManageModalProps) {
  const { account } = useActiveWeb3React()
  const theme = useTheme()

  // track and parse user input
  const [typedStakeValue, setTypedStakeValue] = useState('')
  const [typedCrnValue, setTypedCrnValue] = useState('')
  const [typedUnstakeValue, setTypedUnstakeValue] = useState('')
  const [isUnstaking, setIsUnstaking] = useState(unstake)
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedStakeValue,
    stakingInfo.stakedAmount?.currency,
    userLiquidityUnstaked
  )
  const lockInfo = stakingInfo.lockInfos[lockIndex]
  const { duration } = lockInfo
  const userInfo = stakingInfo.userInfos[lockIndex]

  useEffect(() => {
    if (unstake !== isUnstaking) {
      setIsUnstaking(unstake)
    }
  }, [unstake])

  let unstakeError: ReactNode | undefined
  if (!account) {
    unstakeError = <Trans>Connect a wallet</Trans>
  }
  if (!stakingInfo?.stakedAmount) {
    unstakeError = unstakeError ?? <Trans>Enter an amount</Trans>
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const dummyPair = new Pair(
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[0], '0'),
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[1], '0')
  )
  const lpToken = useCLP({
    lpAddress: stakingInfo.lpAddress,
    token0: stakingInfo.tokens[0],
    token1: stakingInfo.tokens[1],
  })

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approvalA, approveACallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)
  const [approvalB, approveBCallback] = useApproveCallback(
    tryParseCurrencyAmount(typedCrnValue, CRN[stakingInfo.tokens[0].chainId]),
    stakingInfo.stakingRewardAddress
  )

  const contract = useSuperFarmingContract(stakingInfo.stakingRewardAddress)
  const doubleRewardsOn = stakingInfo.doubleRewards
  const doubleRewardToken = stakingInfo.doubleRewardToken
  const noCrnRewards = stakingInfo.noCrnRewards
  const maxUnstakeAmountInput = stakingInfo.stakedAmount ? stakingInfo.stakedAmount : undefined

  async function onStake() {
    setAttempting(true)
    if (contract && parsedAmount && deadline) {
      await contract
        .depositWithExactLP(lockIndex, parseUnits(typedStakeValue))
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
            token0Address: stakingInfo.tokens[0].address,
            token1Address: stakingInfo.tokens[1].address,
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  async function onWithdraw() {
    setAttempting(true)
    if (contract && stakingInfo?.stakedAmount) {
      await contract
        .withdraw(lockIndex)
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.WITHDRAW_LIQUIDITY_STAKING,
            token0Address: stakingInfo.tokens[0].address,
            token1Address: stakingInfo.tokens[1].address,
          })
          setIsUnstaking(false)
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  function handleStaking() {
    setIsUnstaking(false)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  function handleUnstaking() {
    setIsUnstaking(true)
    setTypedStakeValue('')
    setTypedUnstakeValue('')
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (typedValue: string, fromCrn: boolean) => {
      setSignatureData(null)
      if (!fromCrn) {
        const derivedValue = Number(
          tryParseCurrencyAmount(typedValue, new Token(stakingInfo.tokens[0].chainId, stakingInfo.lpAddress, 18))
            ?.divide(stakingInfo.ratio.numerator)
            .multiply(stakingInfo.ratio.decimalScale)
            .toExact()
        )
        setTypedStakeValue(typedValue)
        setTypedCrnValue(String(derivedValue ? derivedValue : ''))
      } else {
        const derivedValue = Number(
          tryParseCurrencyAmount(typedValue, new Token(stakingInfo.tokens[0].chainId, stakingInfo.lpAddress, 18))
            ?.multiply(stakingInfo.ratio.numerator)
            .divide(stakingInfo.ratio.decimalScale)
            .toExact()
        )
        setTypedCrnValue(typedValue)
        setTypedStakeValue(String(derivedValue ? derivedValue : ''))
      }
    },
    [stakingInfo.lpAddress, stakingInfo.ratio, stakingInfo.tokens]
  )

  // used for max input button
  const maxStakeAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const maxCrnAmountInput = maxAmountSpend(userCrnUnstaked)
  const overMaxCrnAmount = +typedCrnValue > +(maxCrnAmountInput?.toExact() ?? 0)

  const atMaxAmount = Boolean(maxStakeAmountInput && parsedAmount?.equalTo(maxStakeAmountInput))
  const atMaxUnstakeAmount = Boolean(
    maxUnstakeAmountInput && tryParseCurrencyAmount(typedUnstakeValue, lpToken)?.equalTo(maxUnstakeAmountInput)
  )
  const handleMax = useCallback(() => {
    if (!isUnstaking && maxStakeAmountInput) {
      onUserInput(maxStakeAmountInput.toExact(), false)
    }
  }, [isUnstaking, maxStakeAmountInput, onUserInput])
  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={100}>
      {!attempting && !hash && (
        <div style={{ width: '100%' }}>
          <ContentWrapper gap="lg">
            <RowBetween>
              <ThemedText.MediumHeader>
                {!isUnstaking ? <Trans>Stake for {duration}</Trans> : <Trans>Withdraw from {duration} stake</Trans>}
              </ThemedText.MediumHeader>
              <CloseIcon onClick={wrappedOnDismiss} />
            </RowBetween>
            {!userInfo.stakedAmount.equalTo('0') && (
              <RowBetween style={{ justifyContent: 'end' }}>
                <Row>
                  <StakeClickableText selected={!isUnstaking} style={{ paddingRight: '10px' }} onClick={handleStaking}>
                    Stake
                  </StakeClickableText>
                  <StakeClickableText selected={isUnstaking} onClick={handleUnstaking}>
                    Unstake
                  </StakeClickableText>
                </Row>
              </RowBetween>
            )}
            {!isUnstaking ? (
              <>
                <CurrencyInputPanel
                  value={!isUnstaking ? typedStakeValue : typedUnstakeValue}
                  fiatValue={stakingInfo.clpPrice.multiply(
                    tryParseCurrencyAmount(typedStakeValue, CRN[stakingInfo.chain])?.divide(
                      10 ** CRN[stakingInfo.chain].decimals
                    ) ?? 0
                  )}
                  onUserInput={(t) => onUserInput(t, false)}
                  onMax={handleMax}
                  showMaxButton={!isUnstaking ? !atMaxAmount : !atMaxUnstakeAmount}
                  currency={lpToken}
                  pair={dummyPair}
                  label={''}
                  renderBalance={(amount) =>
                    !isUnstaking ? (
                      <Trans>Available to deposit: {formatCurrencyAmount(amount, 4)} </Trans>
                    ) : (
                      <Trans>Available to withdraw: {formatCurrencyAmount(maxUnstakeAmountInput, 4)}</Trans>
                    )
                  }
                  id="stake-liquidity-token"
                />
                <ColumnCenter>
                  <Plus size="16" color={theme.text2} />
                </ColumnCenter>
                <CurrencyInputPanel
                  value={typedCrnValue}
                  fiatValue={tryParseCurrencyAmount(String(stakingInfo.crnPrice), USDC[stakingInfo.chain])?.multiply(
                    tryParseCurrencyAmount(typedCrnValue, CRN[stakingInfo.chain])?.divide(
                      10 ** CRN[stakingInfo.chain].decimals
                    ) ?? 0
                  )}
                  onUserInput={(t) => onUserInput(t, true)}
                  showMaxButton={false}
                  currency={CRN[stakingInfo.tokens[0].chainId]}
                  label={''}
                  renderBalance={(amount) => <Trans>Balance: {formatCurrencyAmount(amount, 4)} </Trans>}
                  id="stake-liquidity-token"
                />
              </>
            ) : (
              <>
                <ThemedText.Body>Your balance</ThemedText.Body>
                <ThemedText.Body fontSize="22px">
                  {userInfo.stakedAmount.toFixed(6)} CLP + {userInfo.crnStakedAmount.toFixed(6)} CRN
                </ThemedText.Body>
                <ThemedText.Body>Your unlock date</ThemedText.Body>
                <ThemedText.Body
                  fontSize="22px"
                  display="flex"
                  alignItems="center"
                  color={userInfo.isUnlocked ? 'green1' : 'primary1'}
                >
                  {userInfo.isUnlocked ? (
                    <Unlocked16 style={{ fontSize: '22px', marginRight: '0.5rem' }} />
                  ) : (
                    <Locked16 style={{ fontSize: '22px', marginRight: '0.5rem' }} />
                  )}
                  {userInfo.unlockDate.toDateString()} - {userInfo.unlockDate.toLocaleTimeString()}
                </ThemedText.Body>
              </>
            )}

            <ThemedText.SubHeader style={{ textAlign: 'left', fontWeight: 'bold' }} color="primary1">
              {!isUnstaking ? (
                <Trans>
                  When you deposit the timer will be reset for the assets already staked in this time bracket too!
                </Trans>
              ) : (
                <></>
              )}
            </ThemedText.SubHeader>
            {/* <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <ThemedText.black fontWeight={600}>{t('earn.weeklyRewards')}</ThemedText.black>
            </div>

            <ThemedText.black>
              {hypotheticalRewardRate.multiply((60 * 60 * 24 * 7).toString()).toSignificant(4, { groupSeparator: ',' })}{' '}
              {t('earn.pngWeek')}
            </ThemedText.black>
          </HypotheticalRewardRate> */}
          </ContentWrapper>
          {!isUnstaking ? (
            <RowBetween>
              {!!error || overMaxCrnAmount ? (
                <ButtonError
                  disabled={
                    !!error || overMaxCrnAmount || (signatureData === null && approvalA !== ApprovalState.APPROVED)
                  }
                  error={!!error && !!parsedAmount}
                  onClick={onStake}
                >
                  {error && typedStakeValue ? <> {error}</> : overMaxCrnAmount ? '' : <Trans>Deposit</Trans>}
                  {error && overMaxCrnAmount && typedStakeValue && ' '}
                  {overMaxCrnAmount && !!typedStakeValue && <Trans>Insufficient CRN</Trans>}
                </ButtonError>
              ) : (
                <>
                  {approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING ? (
                    <ButtonConfirmed
                      mr="0"
                      onClick={approveACallback}
                      disabled={approvalA !== ApprovalState.NOT_APPROVED || signatureData !== null}
                    >
                      <Trans>Approve CLP</Trans>
                    </ButtonConfirmed>
                  ) : approvalB === ApprovalState.NOT_APPROVED || approvalB === ApprovalState.PENDING ? (
                    <ButtonConfirmed
                      mr="0"
                      onClick={approveBCallback}
                      disabled={approvalB !== ApprovalState.NOT_APPROVED || signatureData !== null}
                    >
                      <Trans>Approve CRN</Trans>
                    </ButtonConfirmed>
                  ) : (
                    <ButtonError
                      disabled={
                        !!error || overMaxCrnAmount || (signatureData === null && approvalA !== ApprovalState.APPROVED)
                      }
                      error={!!error && !!parsedAmount}
                      onClick={onStake}
                    >
                      {error && typedStakeValue ? <> {error}</> : overMaxCrnAmount ? '' : <Trans>Deposit</Trans>}
                      {overMaxCrnAmount && !!typedStakeValue && <Trans>Insufficient CRN</Trans>}
                    </ButtonError>
                  )}
                </>
              )}
            </RowBetween>
          ) : (
            <ButtonError disabled={!userInfo.isUnlocked} onClick={onWithdraw}>
              {unstakeError ?? <Trans>Withdraw all</Trans>}
            </ButtonError>
          )}
        </div>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          {!isUnstaking ? (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.LargeHeader>
                <Trans>Depositing Liquidity</Trans>
              </ThemedText.LargeHeader>
              <ThemedText.Body fontSize={20}>
                <Trans>{parsedAmount?.toSignificant(4)} CLP</Trans> + <Trans>{typedCrnValue} CRN</Trans>
              </ThemedText.Body>
            </AutoColumn>
          ) : (
            <AutoColumn gap="12px" justify={'center'}>
              <ThemedText.Body fontSize={20}>
                <Trans>Withdrawing {userInfo.stakedAmount.toFixed(6)} CLP</Trans>
              </ThemedText.Body>
              <ThemedText.Body fontSize={20}>
                <Trans>Withdrawing {userInfo.crnStakedAmount.toFixed(6)} CRN</Trans>
              </ThemedText.Body>
              {(doubleRewardsOn || noCrnRewards) && (
                <ThemedText.Body fontSize={20}>
                  <Trans>Claiming</Trans> {stakingInfo?.doubleRewardAmount?.toSignificant(4)} {doubleRewardToken.symbol}
                </ThemedText.Body>
              )}
            </AutoColumn>
          )}
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <ThemedText.LargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.LargeHeader>
            {!isUnstaking ? (
              <ThemedText.Body fontSize={20}>
                <Trans>Deposited {parsedAmount?.toSignificant(4)} CLP</Trans>
              </ThemedText.Body>
            ) : (
              <>
                <ThemedText.Body fontSize={20}>
                  <Trans>Withdrew CLP!</Trans>
                </ThemedText.Body>
                <ThemedText.Body fontSize={20}>
                  <Trans>Claimed CRN!</Trans>
                </ThemedText.Body>
              </>
            )}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
